import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-74ce5c7a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "cart-summary-product-container col-12" }
const _hoisted_2 = { class: "px-2 pt-2 col-12" }
const _hoisted_3 = { class: "d-flex cart-product col-12 bg-secondary-light align-items-center p-1" }
const _hoisted_4 = { class: "col-8 product-info-container" }
const _hoisted_5 = { class: "ps-2 col-12" }
const _hoisted_6 = { class: "product-title col-12 p-xs-primary m-0 text-uppercase" }
const _hoisted_7 = { class: "col-12 p-xs-primary m-0 my-1" }
const _hoisted_8 = { class: "col-12 h-xs-secondary m-0" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PreviewImage = _resolveComponent("PreviewImage")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        (_ctx.product.customizedProduct.previewImageUrl)
          ? (_openBlock(), _createBlock(_component_PreviewImage, {
              key: 0,
              class: "product-image-container col-4 flex-centered",
              path: `${_ctx.uploadPath}/${_ctx.product.customizedProduct.previewImageUrl}`
            }, null, 8, ["path"]))
          : _createCommentVNode("", true),
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("p", _hoisted_6, _toDisplayString(_ctx.product.customizedProduct.name), 1),
            _createElementVNode("p", _hoisted_7, "Qty: " + _toDisplayString(_ctx.quantityPerItem(_ctx.product)), 1),
            _createElementVNode("h6", _hoisted_8, _toDisplayString(_ctx.currency().symbol) + _toDisplayString(_ctx.thousandSeparator(_ctx.calculatePrice(_ctx.product.price * _ctx.quantityPerItem(_ctx.product), _ctx.currency().pricingMultiplier))), 1)
          ])
        ])
      ])
    ])
  ]))
}