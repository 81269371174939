import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = ["width", "height", "transform", "transform-origin", "xlink:href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.modelValue)
    ? (_openBlock(), _createElementBlock("image", {
        width: _ctx.width,
        height: _ctx.height,
        transform: _ctx.imageTransform,
        "transform-origin": _ctx.imageTransformOrigin,
        "xlink:href": `${_ctx.uploadPath}/${_ctx.modelValue.src}`,
        key: _ctx.modelValue.id
      }, null, 8, _hoisted_1))
    : _createCommentVNode("", true)
}