import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveDynamicComponent as _resolveDynamicComponent, normalizeStyle as _normalizeStyle, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-14841a60"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["id"]
const _hoisted_2 = { key: 0 }
const _hoisted_3 = ["id", "width", "height"]
const _hoisted_4 = ["xlink:href", "width", "height"]
const _hoisted_5 = ["id"]
const _hoisted_6 = ["xlink:href"]
const _hoisted_7 = ["mask", "fill"]
const _hoisted_8 = ["mask", "xlink:href"]
const _hoisted_9 = ["mask", "fill"]
const _hoisted_10 = ["xlink:href"]
const _hoisted_11 = ["xlink:href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InvertTransform = _resolveComponent("InvertTransform")!
  const _component_ControlBox = _resolveComponent("ControlBox")!

  return (_openBlock(), _createElementBlock("svg", {
    xmlns: "http://www.w3.org/2000/svg",
    "xmlns:xlink": "http://www.w3.org/1999/xlink",
    id: _ctx.hideControls ? 'product-render' : 'product-view',
    crossorigin: "anonymous",
    viewBox: "0 0 680 800",
    style: {"max-width":"680px","width":"100%","height":"100%"},
    ref: "box",
    class: "box",
    onMouseup: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.eventStop && _ctx.eventStop(...args))),
    onTouchend: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.eventStop && _ctx.eventStop(...args))),
    onMouseleave: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.eventStop && _ctx.eventStop(...args))),
    onTouchcancel: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (_ctx.eventStop && _ctx.eventStop(...args)))
  }, [
    _createElementVNode("rect", {
      width: "100%",
      height: "100%",
      fill: "transparent",
      "stroke-width": "5",
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.selectItem(null)))
    }),
    _createVNode(_component_InvertTransform),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.modelValue.layers, (layer, index) => {
      return (_openBlock(), _createElementBlock(_Fragment, { key: index }, [
        (layer.fabric.colour.localPattern)
          ? (_openBlock(), _createElementBlock("defs", _hoisted_2, [
              _createElementVNode("pattern", {
                id: index + '_' + _ctx.view + '_' +`${_ctx.convertToSnakeCase(layer.name)}-local-layer`,
                x: "0",
                y: "0",
                width: 1 / layer.fabric.colour.localPattern.repeatCount,
                height: 1 / layer.fabric.colour.localPattern.repeatCount
              }, [
                _createElementVNode("image", {
                  "xlink:href": layer.fabric.colour.localPattern.data,
                  width: 680 / layer.fabric.colour.localPattern.repeatCount,
                  height: 800 / layer.fabric.colour.localPattern.repeatCount
                }, null, 8, _hoisted_4)
              ], 8, _hoisted_3)
            ]))
          : _createCommentVNode("", true),
        _createElementVNode("mask", {
          id: index + '_' + _ctx.view + '_' + _ctx.convertToSnakeCase(layer.name)
        }, [
          _createElementVNode("image", {
            "xlink:href": `${_ctx.uploadPath}/` + (_ctx.view == 0 ? layer.frontMaskUrl: layer.backMaskUrl),
            class: "mask__image",
            filter: "url(#invert)"
          }, null, 8, _hoisted_6)
        ], 8, _hoisted_5),
        (layer.fabric.colour.localPattern)
          ? (_openBlock(), _createElementBlock("rect", {
              key: 1,
              mask: 'url(#' + index + '_' + _ctx.view + '_' + _ctx.convertToSnakeCase(layer.name) + ')',
              fill: `url(#${index + '_' + _ctx.view + '_' + _ctx.convertToSnakeCase(layer.name)}-local-layer)`,
              width: "100%",
              height: "100%"
            }, null, 8, _hoisted_7))
          : (layer.fabric.colour.patternImageUrl)
            ? (_openBlock(), _createElementBlock("image", {
                key: 2,
                mask: 'url(#' + index + '_' + _ctx.view + '_' + _ctx.convertToSnakeCase(layer.name) + ')',
                style: {"pointer-events":"none"},
                "xlink:href": `${_ctx.uploadPath}/${layer.fabric.colour.patternImageUrl}`
              }, null, 8, _hoisted_8))
            : (_openBlock(), _createElementBlock("rect", {
                key: 3,
                width: "100%",
                height: "100%",
                mask: 'url(#' + index + '_' + _ctx.view + '_' + _ctx.convertToSnakeCase(layer.name) + ')',
                style: {"pointer-events":"none"},
                fill: layer.fabric.colour.id ? `${layer.fabric.colour.hexValue}` : 'transparent'
              }, null, 8, _hoisted_9))
      ], 64))
    }), 128)),
    (_ctx.selectedStyle)
      ? (_openBlock(), _createElementBlock("image", {
          key: 0,
          style: {"pointer-events":"none"},
          "xlink:href": `${_ctx.uploadPath}/` + (_ctx.view == 0 ? _ctx.selectedStyle.frontShadowMaskUrl : _ctx.selectedStyle.backShadowMaskUrl)
        }, null, 8, _hoisted_10))
      : _createCommentVNode("", true),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.modelValue.extras, (extra, index) => {
      return (_openBlock(), _createElementBlock(_Fragment, { key: index }, [
        ((_ctx.view == 0 ? extra.frontShadowMaskUrl : extra.backShadowMaskUrl))
          ? (_openBlock(), _createElementBlock("image", {
              key: 0,
              style: {"pointer-events":"none"},
              "xlink:href": `${_ctx.uploadPath}/` + (_ctx.view == 0 ? extra.frontShadowMaskUrl : extra.backShadowMaskUrl)
            }, null, 8, _hoisted_11))
          : _createCommentVNode("", true)
      ], 64))
    }), 128)),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.viewItems(), (item, index) => {
      return (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.dynamicComponent(item)), {
        onClick: ($event: any) => (_ctx.selectItem(item)),
        onSizeUpdated: ($event: any) => (_ctx.sizeUpdated($event, item)),
        modelValue: _ctx.viewItems()[index],
        key: index + item.view,
        style: _normalizeStyle(_ctx.cursor(item))
      }, null, 8, ["onClick", "onSizeUpdated", "modelValue", "style"]))
    }), 128)),
    (_ctx.selectedItem && !_ctx.hideControls)
      ? (_openBlock(), _createBlock(_component_ControlBox, {
          width: _ctx.controlboxWidth(),
          height: _ctx.controlboxHeight(),
          "transform-origin": _ctx.center(_ctx.selectedItem, _ctx.controlboxWidth(), _ctx.controlboxHeight()),
          transform: _ctx.transform(_ctx.selectedItem, _ctx.controlboxWidth(), _ctx.controlboxHeight()),
          scalable: _ctx.selectedItem.type != 'text',
          key: _ctx.selectedItem.id,
          selectedItem: _ctx.selectedItem,
          isAdminOrder: _ctx.isAdminOrder,
          onScaleStart: _ctx.scaleStart,
          onScaleStop: _ctx.eventStop,
          onRotateStart: _ctx.rotateStart,
          onRotateStop: _ctx.eventStop,
          onDrag: _ctx.drag,
          onDragStop: _ctx.eventStop,
          onRemoveElement: _ctx.removeElement
        }, null, 8, ["width", "height", "transform-origin", "transform", "scalable", "selectedItem", "isAdminOrder", "onScaleStart", "onScaleStop", "onRotateStart", "onRotateStop", "onDrag", "onDragStop", "onRemoveElement"]))
      : _createCommentVNode("", true)
  ], 40, _hoisted_1))
}