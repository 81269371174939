
import { Splide, SplideSlide } from '@splidejs/vue-splide';
import { mixins, Options } from "vue-class-component";
import { PropType } from "vue";
import '@splidejs/splide/dist/css/themes/splide-default.min.css';
import { UploadPath } from '@/mixins/utilities';
import { CarouselItemAdminListViewModel } from "@/api-client";
import { CarouselItems } from '@/network/api';
import Markdown from 'vue3-markdown-it';
import { marked } from "marked";

@Options({
  components: {Splide, SplideSlide, Markdown},
  props: {
    section: { type: String as PropType<"Carousel 1" | "Carousel 2"> , default: "Carousel 1" },
    altText: { default: '' },
    aboveDesignNowButton: { default: false }
  },
})
export default class SplideCarousel extends mixins(UploadPath) {
  spliderConfig = {
    type: 'loop',
    autoplay: false,
    pagination: true,
    // resetProgress: true,
    // pauseOnHover: true,
    // pauseOnFocus: false,
    // interval: 5000,
    arrows: false,
  }
  carouselItems: CarouselItemAdminListViewModel[] = []
  section: "Carousel 1" | "Carousel 2" = "Carousel 1";

  created() {
    this.loadInitialData();
  }

  formattedMarkdown(text: string) {
    if (text) {
      let markedDetails = marked(text)
      markedDetails = markedDetails.replaceAll('<p>', "");
      markedDetails = markedDetails.replaceAll('</p>', "");
      markedDetails = markedDetails.replaceAll('<div>', "");
      markedDetails = markedDetails.replaceAll('</div>', "");
      markedDetails = markedDetails.replaceAll('\n', "<br>");

      // Remove <br> if at the end of string
      markedDetails = markedDetails.replace(/<br\s*\/?>$/, '');

      return markedDetails;
    }
    return "";
  }

  async loadInitialData() {
    await CarouselItems.carouselItemsGet(1, 99999, this.section)
    .then((res) => {
      this.carouselItems = res.data.resultData?.items as Array<CarouselItemAdminListViewModel>;
    })
    .catch((error) => {
      console.log(error);
      console.log(error);
    });
  }
}
