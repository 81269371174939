
import { DeliveryType, OrderCartViewModel, OrderStatus } from "@/api-client";
import { IsLoggedIn, UserRole } from "@/mixins/utilities";
import { store } from "@/store";
import { mixins, Options, Vue } from "vue-class-component";

@Options({
  props: {
    showGeneral: { default: true },
    selectedSection: { default: null },
    customizedProduct: { default: null },
    enableSave: { default: null },
    cart: {
      default: null,
    },
    showClose: { default: false },
    isAdminOrder: {
      default: false,
    },
    isInCurrentCart: {
      default: null,
    },
    storeId: {
      default: null
    },
    isInStore: { default: false }
  },
  emits: ["toggleGeneral", "swap", "handleClick", "reset", "checkout", "addToCart", "save", "return", "close", "addToStore"],
})
export default class GeneralOptions extends mixins(UserRole, IsLoggedIn) {
  showGeneral = true;
  designTitle = "";
  title = "";
  enableSave: any = {
    customer: undefined,
    admin: undefined,
  };
  customizedProduct: any = false;
  constantButtons = [
    {
      name: "Share",
      id: "share",
      icon: "/img/designer/share-icon.png",
    },
    {
      name: "Enquire",
      id: "enquire",
      icon: "/img/designer/enquire-icon.png",
    },
    {
      name: "Save",
      id: "save",
      icon: "/img/designer/save-icon.png",
    },
    {
      name: "Save As",
      id: "saveas",
      icon: "/img/designer/save-icon.png",
    },
  ];
  reset = {
    name: "Reset",
    id: "resetTemplate",
    icon: "/img/designer/reset-icon.png",
  };
  currentView = 0;
  cart: OrderCartViewModel = {
    id: "",
    items: [],
    countryId: "",
    isGift: false,
    deliveryTypeId: DeliveryType.Standard,
    orderStatusId: OrderStatus.Cart,
    currencyId: "",
    currencyPricingMultiplyer: 1,
    requiresCustomerApproval: true,
    additionalCostsConvertedTotal: 0,
    orderDocuments: [],
    isQuote: false,
    referenceNumber: "",
    deliveryAddress: {
      id: "",
      addressLine1: "",
      area: "",
      country: "",
      postalCode: "",
    },
    billingAddress: {
      id: "",
      addressLine1: "",
      area: "",
      country: "",
      postalCode: "",
    },
    customerContact: {
      id: "",
      name: "",
      email: "",
      phoneNumber: "",
    },
    accessKey: "",
    allowedPaymentProviders: [],
    paymentsTotal: 0,
    paymentsConvertedTotal: 0,
    customerContacts: []
  };
  isInCurrentCart = false;

  get enableSaveByRole() {
    if (this.userRole && this.enableSave) {
      if (((this.userRole.includes("Admin") || this.userRole.includes("Sales Admin"))  && this.enableSave.admin) || this.enableSave.customer) {
        return true;
      }
    }
    return false;
  }

  get buyButtonText() {
    if (this.cart && this.cart.isQuote) {
      if (this.isInCurrentCart && this.enableSave.admin) {
        return "Update";
      }
      return "Quote";
    }

    if (this.isInCurrentCart && this.enableSave.customer) {
      return "Update";
    }

    return null;
  }

  isDisabled(id: string) {
    if (id == "save" && (this.enableSave.customer || this.enableSaveByRole) && this.customizedProduct) {
      return false;
    }

    // if (!this.isLoggedIn && (id == "save" || id == "saveas")) {
    //   return true;
    // }

    if ((!this.customizedProduct || !this.enableSaveByRole) && id == "save") {
      return true;
    }

    return false;
  }

  isNotAvailable(id: string) {
    // if (!this.isLoggedIn && id == "save" && this.enableSave.customer) {
    //   return false;
    // }

    // if (!this.isLoggedIn && (id == "save" || id == "saveas")) {
    //   return true;
    // }

    // Before, above logic was to add label "Log in to save designs"
    return false;
  }

  toggleGeneral() {
    this.$emit("toggleGeneral");
  }

  swapView() {
    if (this.currentView === 0) {
      this.currentView = 1;
    } else {
      this.currentView = 0;
    }

    this.$emit("swap", this.currentView);
  }
}
