
import { Options, mixins } from "vue-class-component";
import { CalculatePrice, Currency, GetPriceByQuantity, UploadPath } from "@/mixins/utilities";
import PatternDesigner from "@/features/pattern-designer/components/PatternDesigner.vue";
import { ProductBuilderFabricChannelViewModel, ProductBuilderFontViewModel } from "@/api-client";
import { store } from "@/store";
import { Popover } from 'bootstrap/dist/js/bootstrap.esm.js'

@Options({
  components: { PatternDesigner },
  props: {
    fonts: {
      type: Array,
      default: [],
    },
    layers: {
      type: Object,
      required: true,
    },
    selectedLayers: {
      type: Object,
    },
    quantity: { default: 0 },
    selectedTab: {
      type: String,
      default: "",
    },
    sectionHeight: { default: 0 },
    designing: { default: false },
    setPrevColour: { default: false },
    fabricChannels: {
      type: Array,
      default: () => [],
    },
  },
  emits: ["setColour", "handleDesigning", "resetSetPrevColour", "setFabric"],
})
export default class FabricColourPicker extends mixins(UploadPath, CalculatePrice, GetPriceByQuantity, Currency) {
  designing = false;
  fonts: Array<ProductBuilderFontViewModel> = [];

  layers: any = [];
  selectedLayers: any = [];

  // internal data state
  selectedLayer: any = {};
  selectedFabric: any = {};
  selectedColour: any = {};
  previousColourInfo: any = {};
  customPatternPrices: any = [];
  setPrevColour = false;
  sectionHeight = 500;
  fabricChannels: Array<ProductBuilderFabricChannelViewModel> = [];
  selectedfabricChannel: any = null;

  get groupedFabricChannels() {
    if (this.fabricChannels.length) {
      let grouped: Array<ProductBuilderFabricChannelViewModel> = [];
      let layersWithoutFabricChannel = this.selectedLayers.filter((layer: any) => {
        return !layer.fabricChannelId;
      });
      const fabricChannelIds: Array<string> = [];

      if (layersWithoutFabricChannel?.length !== this.selectedLayers.length) {
        this.fabricChannels.forEach((fabricChannel: ProductBuilderFabricChannelViewModel) => {
          if (!fabricChannelIds.includes(fabricChannel.id)) {
            grouped.push(fabricChannel);
            fabricChannelIds.push(fabricChannel.id);
          } else {
            grouped.forEach((group) => {
              fabricChannel.fabrics.forEach((fabric) => {
                const hasFabric = group.fabrics.findIndex((item) => item.id === fabric.id);
                if (hasFabric == -1) {
                  group.fabrics.push(fabric);
                }
              });
            });
          }
        });

        if (layersWithoutFabricChannel?.length) {
          grouped.push({
            id: null as any,
            externalName: "Contrast",
            fabrics: [],
          });
        }
        return grouped;
      }
    }
    return [
      {
        id: null,
        externalName: "Color & Pattern",
        fabrics: [],
      },
    ];
  }

  created() {
    if(this.groupedFabricChannels.length) {
      this.selectedfabricChannel = this.groupedFabricChannels[0];
    }

    if(this.fabricChannels.length) {
      const defaultLayer = this.layers.find((layer:any) => layer.fabricChannelId === this.selectedfabricChannel.id);
      this.handleSelectLayer(defaultLayer);
    } else {
      this.handleSelectLayer(this.layers[0]);
    }
    this.$watch("selectedTab", () => {
      this.selectedfabricChannel = this.groupedFabricChannels[0];
      if(this.fabricChannels.length) {
        const defaultLayer = this.layers.find((layer:any) => layer.fabricChannelId === this.selectedfabricChannel.id);
        this.handleSelectLayer(defaultLayer);
      } else {
        this.handleSelectLayer(this.layers[0]);
      }
      this.initiatePopovers()
    });

    this.$watch("setPrevColour", () => {
      if (this.setPrevColour) {
        this.handleDesignPattern(false);
        this.$emit("resetSetPrevColour");
      }
    });

    this.$watch('selectedfabricChannel', () => {
      if(!this.selectedLayer) {
        setTimeout(() => {
          this.initiatePopovers()
        }, 0);
      }
    })
  }

  mounted() {
    this.initiatePopovers()
  }

  initiatePopovers() {
    // inti Popover
    setTimeout(() => {
      let popoverTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="popover"]'))
    popoverTriggerList.map(function (popoverTriggerEl:any) {
      if(popoverTriggerEl.dataset.bsContent) {
        return new Popover(popoverTriggerEl, { trigger: 'hover focus' })
      }
    })
    }, 0);
  }

  getFabricDescription(fabricChannel:any) {
    if(Object.prototype.hasOwnProperty.call(fabricChannel, 'selectedId')) {
      this.initiatePopovers()    
      const selectedFabric = fabricChannel.fabrics.find((fabric: any) => fabric.id === fabricChannel.selectedId);
      return selectedFabric ? selectedFabric.description : ''
    }
    return ''
  }

  fabricPrice(fabric:any) {
    if(fabric.price > 0) {
      return [{
        id: '',
        maximumQuantity: null,
        minimumQuantity: null,
        price: fabric.price
      }]
    } 

    return []
  }

  setFabricOnLayers({target}:any, fabricChannelId: string) {
    let selectedFabrics:any = {};
    let backupColour: any = null;

    for(let i = 0; i < this.layers.length; i++) {
      if(fabricChannelId) {
        const fabric = this.layers[i].fabrics.find((fabric:any) => fabric.id === target.value);

        if(fabric) {
          selectedFabrics[`${this.layers[i].id}`] = this.selectedFabric = fabric;

          const checkCustomPatterns = this.customPatterns().find((x: any) => x.patternImageUrl === this.selectedColour.patternImageUrl);
          const firstNonPatternColour = fabric.colours.find((fabric:any) => !fabric.patternImageUrl);
          
          backupColour = checkCustomPatterns || firstNonPatternColour || fabric.colours[0];

          const checkNewFabricHasColour = this.selectedFabric.colours.find((x: any) => x.id === this.selectedColour.id);

          if(!checkNewFabricHasColour && backupColour) {
            this.selectedColour = backupColour;
          }
        }
      } else {
        selectedFabrics = this.layers[i].fabrics.find((fabric:any) => fabric.id === target.value)
        if(selectedFabrics) {
          this.selectedFabric = selectedFabrics;
          const checkCustomPatterns = this.customPatterns().find((x: any) => x.patternImageUrl === this.selectedColour.patternImageUrl);
          const firstNonPatternColour = selectedFabrics.colours[0].find((fabric:any) => !fabric.patternImageUrl);
          
          backupColour = checkCustomPatterns || firstNonPatternColour || selectedFabrics.colours[0]

          const checkNewFabricHasColour = this.selectedFabric.colours.find((x: any) => x.id === this.selectedColour.id);

          if(!checkNewFabricHasColour && backupColour) {
            this.selectedColour = backupColour;
          }
          break;
        }
      }
    }

    this.$emit("setFabric", { fabrics: selectedFabrics, fabricChannelId:fabricChannelId, backupColour: backupColour })
  }

  correctSymbol(prices: any, quantity: any) {
    const price = this.calculatePrice(this.getPriceByQuantity(prices, quantity) as number, this.currency().pricingMultiplier);
    return +price < 0 ? "-" : "+";
  }

  handleChannelClick(fabricChannel: ProductBuilderFabricChannelViewModel) {
    const defaultLayer = this.layers.find((layer:any) => layer.fabricChannelId === fabricChannel.id);
    if(defaultLayer) {
      this.handleSelectLayer(defaultLayer)
    }

    this.selectedfabricChannel = fabricChannel;
    this.initiatePopovers()
  }

  customPatterns() {
    const customPatterns = store.getters["user/customPatterns"];
    if (customPatterns) {
      return customPatterns;
    }
    return [];
  }

  get isSublimate() {
    if (this.selectedFabric?.constructionTypeId) {
      return this.selectedFabric.constructionTypeId == "Sublimation";
    }
    return false;
  }

  customPatternInfo(customPattern: any) {
    let hasId = Object.prototype.hasOwnProperty.call(customPattern, "id") && customPattern.id != null;
    let hasLocal = Object.prototype.hasOwnProperty.call(customPattern, "localPattern");
    let fabricPrice = [] as Array<any>;

    if (this.selectedFabric.customPatternPrice > 0) {
      fabricPrice = [
        {
          id: "",
          maximumQuantity: null,
          minimumQuantity: null,
          price: this.selectedFabric.customPatternPrice,
        },
      ];
    }

    if (!hasId && hasLocal) {
      return { isCustom: true, imageUrl: customPattern.localPattern, prices: fabricPrice };
    } else if (!hasId && !hasLocal) {
      return { isCustom: true, imageUrl: customPattern.patternImageUrl, prices: fabricPrice };
    }
    return { isCustom: false };
  }

  sortedFreeFabrics(fabrics: any) {
    let array = fabrics.filter((colour: any) => colour.prices.length === 0);
    return array.sort((a: any, b: any) => {
      if (a.patternImageUrl) {
        return 1;
      }
      if (b.patternImageUrl) {
        return -1;
      }
      return 0;
    });
  }

  sortedPaidFabrics(fabrics: any) {
    let array = fabrics.filter((colour: any) => colour.prices.length > 0);
    return array.sort((a: any, b: any) => {
      if (a.patternImageUrl) {
        return 1;
      }
      if (b.patternImageUrl) {
        return -1;
      }
      return 0;
    });
  }

  handleDesignPattern(toDesigner: boolean) {
    if (toDesigner) {
      this.previousColourInfo = {
        colour: { ...this.selectedColour },
        fabric: { ...this.selectedFabric },
        layer: { ...this.selectedLayer },
      };
    } else {
      this.selectedColour = { ...this.previousColourInfo.colour };
      this.selectColour(this.previousColourInfo.colour, this.previousColourInfo.fabric, this.previousColourInfo.layer);
    }
    this.$emit("handleDesigning", toDesigner);
  }

  patternUpdated(patternData: any) {
    if (patternData.patternImageUrl) {
      // This is the case when the image has been saved and the deisgn refernce is no to the saved image not the local data.
      this.selectedColour = {
        patternImageUrl: patternData.patternImageUrl,
        highQualityPatternImageUrl: patternData.highQualityPatternImageUrl || null
      };
      this.selectColour(this.selectedColour, this.selectedFabric, this.selectedLayer);
      // this.$emit("setColour", { layer: this.selectedLayer, fabric: this.selectedLayer.fabrics[0], colour: { patternImageUrl: patternData.patternImageUrl } });
    } else {
      this.selectColour({ localPattern: patternData }, this.selectedFabric, this.selectedLayer);
      // this.$emit("setColour", { layer: this.selectedLayer, fabric: this.selectedLayer.fabrics[0], colour: { localPattern: patternData } });
    }
  }

  getSelectedLayerFromID(id: string) {
    const selectedLayer = this.selectedLayers.find((layer: any) => layer.id === id);
    return selectedLayer;
  }

  handleSelectLayer(layer: any) {
    this.selectedLayer = layer;

    if(this.selectedfabricChannel && Object.prototype.hasOwnProperty.call(this.selectedfabricChannel, 'selectedId')) {
      const fabric = this.selectedLayer.fabrics.find((layerFabric:any) => layerFabric.id === this.selectedfabricChannel.selectedId);
      if(fabric) {
        this.selectedFabric = fabric;
      } else {
        this.selectedFabric = this.selectedLayer.fabrics[0];
      }
    } else {
      this.selectedFabric = this.selectedLayer.fabrics[0];
    }

    const colour = this.getSelectedLayerFromID(layer.id)?.fabric?.colour;
    if(colour) {
      this.selectedColour = colour;
    } else {
      this.selectedColour = this.selectedFabric.colours[0];
    }
    
    if (this.selectedFabric.customPatternPrice > 0) {
      this.customPatternPrices = [
        {
          id: "",
          maximumQuantity: null,
          minimumQuantity: null,
          price: this.selectedFabric.customPatternPrice,
        },
      ];
    } else {
      this.customPatternPrices = [];
    }
  }

  handleColourSelect(colour: any, fabric: any, selectedLayer: any) {
    this.selectedColour = { ...colour };
    this.selectColour(colour, fabric, selectedLayer);
  }

  determineLayerThumbnail(id: any) {
    let selectedLayer = this.getSelectedLayerFromID(id);
    if(selectedLayer) {
      if (selectedLayer.fabric?.colour?.patternImageUrl) {
        return "pattern";
      } else if (selectedLayer.fabric?.colour?.hexValue) {
        return "hex";
      }
    }
    return "";
  }

  selectColour(colour: any, fabric: any, layer: any) {
    this.$emit("setColour", { layer, fabric, colour });
  }
}
