
import { Options, Vue } from "vue-class-component";

@Options({
  props: {
    tileName: { type: String, default: "Product" },
    tileBgUrl: {
      type: String,
      default: "/img/clifton-product-images-1.1.jpg",
    },
    infoLinkTo: { default: { name: "AllCategories" } },
  },
})
export default class ProductGlobalSearchResultTile extends Vue {
  tileName = "Product";
  tileBgUrl = "/img/clifton-product-images-1.1.jpg";
  infoLinkTo = "AllCategories";
}
