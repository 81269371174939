import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle } from "vue"

const _hoisted_1 = { class: "heading-banner-container container-fluid w-100 m-0 p-0" }
const _hoisted_2 = {
  key: 0,
  class: "heading-banner-heading-container w-100 d-flex flex-column justify-content-center p-0"
}
const _hoisted_3 = ["innerHTML"]
const _hoisted_4 = { class: "d-flex w-100 h-100 position-absolute img-wrap p-0" }
const _hoisted_5 = ["src"]
const _hoisted_6 = {
  key: 1,
  class: "w-100 h-100 image-placeholder"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: _normalizeClass(["relative w-100 heading-banner-image-container", {
        'short-heading-banner-image-container':_ctx.short,
        'tall-banner-image-container':!_ctx.short,
        'smaller-text': _ctx.smallerText
      }])
    }, [
      (_ctx.banner.src)
        ? (_openBlock(), _createElementBlock("div", {
            key: 0,
            style: _normalizeStyle(`background-position: ${_ctx.position};`),
            class: _normalizeClass(["row m-0 p-0 w-100 bg-image", _ctx.short ? 'short-bg-image' : 'tall-bg-image'])
          }, [
            (_ctx.title)
              ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                  _createElementVNode("h1", {
                    innerHTML: _ctx.title,
                    style: {"line-height":"1"},
                    class: _normalizeClass(["h-lg-secondary px-2 px-sm-4 py-1 py-sm-3 bg-white", {' my-md-3': _ctx.short}])
                  }, null, 10, _hoisted_3)
                ]))
              : _createCommentVNode("", true),
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("img", {
                src: _ctx.banner.src,
                alt: "Banner image"
              }, null, 8, _hoisted_5)
            ])
          ], 6))
        : (_openBlock(), _createElementBlock("div", _hoisted_6))
    ], 2)
  ]))
}