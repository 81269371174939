
import { store } from "@/store";
import { Options, Vue } from "vue-class-component";

@Options({

})
export default class DesignerMobileNav extends Vue {
  storeId = '';

  get storeCart() {
    return store.getters["checkout/cart"];
  }

  get userRole() {
    return store.getters["user/role"];
  }

  created() {
    if(this.$route.query.store) {
      this.storeId = this.$route.query.store as string;
    }
  }
}
