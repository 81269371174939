
import { UploadPath } from "@/mixins/utilities";
import { mixins, Options, Vue } from "vue-class-component";

@Options({
  props: {
    heading: {default: ''},
    categories: {default: []},
    selectedCategories: {default: []},
    colour: {default: ''},
    paragraph: {default: ''},
    image: {default: ''},
    slug: {default: ''}
  }

})
export default class BlogPostTile extends mixins(UploadPath) {
  heading = '';
  categories = [];
  selectedCategories = [];
  colour = '';
  paragraph = '';
  image = '';
  slug = '';

  get truncatedHeading() {
    if (this.heading.length <= 60) {
      return this.heading;
    }
    return this.heading.slice(0, 60) + '...'
  }

  // get truncatedParagraph() {
  //   if (this.paragraph.length < 130) {
  //     return this.paragraph;
  //   } 
  //   return this.paragraph.slice(0, 130) + '...'
  // }
}
