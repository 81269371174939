
import { mixins, Options, Vue} from "vue-class-component";
import BuilderImage from "./render-types/BuilderImage.vue";
import BuilderText from "./render-types/BuilderText.vue";
import InvertTransform from "./svg-utils/InvertTransform.vue";
import ControlBox from "../editor/ControlBox.vue";
import { UploadPath } from "@/mixins/utilities";
import { SelectedItem } from "@/types";
import { PropType } from "vue";
import { ProductBuilderStyleViewModel } from "@/api-client";


@Options({
  props: {
    modelValue: { type: Object, required: true },
    view: {type: Number, required: false, default: 0},
    hideControls: {type: Boolean, required: false, default: false},
    id: {type: String, required: false, default: ''},
    selectedStyle: { type: Object as PropType<ProductBuilderStyleViewModel>, required: true },
    isAdminOrder: {type: Boolean, required: false, default: false},
  },
  emits: ["itemSelected", "removeElement"],
  components: { BuilderImage, BuilderText, ControlBox, InvertTransform },
})
export default class ProductView extends mixins(UploadPath) {
  selectedItem: SelectedItem | null = null;
  modelValue: any;
  view = 0;
  hideControls = false;
  debug = false;
  isAdminOrder = false;

// This link is your friend for all the SVG things
// https://developer.mozilla.org/en-US/docs/Web/API/SVGGraphicsElement

  selectItem(item: any, noEmit=false, isDuplicate=false) {
    this.eventStop(); //  make sure listeners are cleared on drop
    if(isDuplicate && Object.prototype.hasOwnProperty.call(item, "tempId")) {
      delete item.tempId

      if(Object.prototype.hasOwnProperty.call(item, "id")) {
        delete item.id
      }
      
      item.x -= 10
      item.y -= 10
    }
    if (item == null) {
      this.selectedItem = null;
    }else if (!item.disabled) {
      if (!(item.width && item.height)) {
        item.width = 100;
        item.height = 100;
      }

      this.selectedItem = item;
    }
    if(!noEmit){
      this.$emit('itemSelected', item, isDuplicate)
    }
  }

  sizeUpdated(size: any, item: any){
    item.height = size.height;
    item.width = size.width;
    if(item == this.selectedItem){
      this.selectedItem!.width = size.width;
      this.selectedItem!.height = size.height; 
    }
  }

  getEvent(event: any, box: SVGSVGElement): any {
    var touch: any;
    var eventName;
    if (event.touches) {
      touch = event.touches[0];
      eventName = "touchmove";
    } else {
      touch = event;
      eventName = "mousemove";
    }
    var size = box.getBoundingClientRect();
    var x = touch.clientX - size.left;
    var y = touch.clientY - size.top;
    var scale = size.width / box.viewBox.baseVal.width;
    return { point: { x: x / scale, y: y / scale }, eventName };
  }

  cursor(item: any) {
    if (!this.isAdminOrder && item?.extraId) {
      return "cursor: pointer;";
    }

    if (item.disabled || this.hideControls) {
      return "pointer-events:none;";
    }
    // return `cursor: ${item.id == this.draggedID ? "grabbing" : "grab"}`;
    
    // return this.selectedItem != null && this.selectedItem.id == item.id ? "pointer-events:none;" : "cursor: grab;";

    return "cursor: grab;";
  }

  cursorControls() {
    if ((this.selectedItem?.extraId && !this.isAdminOrder) || this.hideControls) {
      return "pointer-events:none;";
    }
    return `cursor: ${
      this.selectedItem != null && this.dragging ? "grabbing" : "grab"
    }`;
  }

  // a function that will convert display titles to snake case
  convertToSnakeCase(input: string) {
    if (input){
      return input.replace(/[^a-zA-Z0-9]/g, "_").toLowerCase();
    }
    return '';
  }

  dynamicComponent(item: any) {
    if (item.type == "image") {
      return "builder-image";
    } else if (item.type == "text") {
      return "builder-text";
    }
    return "rect";
  }

  // Controls

  dragOffsetX: number | null = null;
  dragOffsetY: number | null = null;

  viewItems(){
    return this.modelValue.items.filter((item:any) => item.view == this.view)
  }

  bringToTop(item: any) {
    if (item) {
      // this.modelValue.items.sort((a: any, b: any) =>
      //   b.id == item.id ? -1 : a.order - b.order
      // );
      var index = this.modelValue.items.indexOf(item);
      this.modelValue.items.splice(this.modelValue.items.length - 1, 0, this.modelValue.items.splice(index, 1)[0]);

    } else {
      this.modelValue.items.sort((a: any, b: any) => a.order - b.order);
    }
  }

  controlboxWidth() {
    return (this.selectedItem!.width ?? 100) + 10;
  }
  controlboxHeight() {
    return (this.selectedItem!.height ?? 100) + 10;
  }

  dragging = false;


  center(item: any, width = 0, height = 0) {
    if (item) {
      // debugger
      if (item.height && item.width) {
        return `${(item.width + (width - item.width)) / 2} ${(item.height + (height - item.height)) / 2}`;
      }
      return `50 50`;
    }
    return "0 0";
  }

  transform(item: any, width = 0, height = 0) {
    var transformString = "";
    if (item) {
      if (item.x || item.y) {
        // debugger
        transformString += `translate(${item.x - width / 2} ${item.y - height / 2})`;
      }
      if (item.rotate) {
        transformString += ` rotate(${item.rotate})`;
      }
      if (item.scale) {
        transformString += ` scale(${item.scale})`;
      }
    }
    return transformString;
  }

  // ROTATE
  rotateStart(event: any) {
    if (!this.selectedItem) {
      return;
    }
    this.dragging = true;

    var e = this.getEvent(event, this.$refs.box as SVGSVGElement);
    // this.bringToTop(this.selectedItem);
    this.dragOffsetX = e.point.x;
    this.dragOffsetY = e.point.y;
    this.controlBoxCenterPt = `${this.dragOffsetX!} ${this.dragOffsetY!}`;

    console.log("ROTATE ON");
    (this.$refs.box as any).addEventListener(e.eventName, this.rotate);
  }

  controlBoxCenterPt = "0 0";
  testCenterPt = "0 0";

  rotate(event: any) {
    var e = this.getEvent(event, this.$refs.box as SVGSVGElement);

    if (this.selectedItem) {

      var x = this.selectedItem.x;
      var y = this.selectedItem.y;
      
      this.testCenterPt = `${this.selectedItem.x} ${this.selectedItem.y}`;

      var xDiff = e.point.x - x;
      var yDiff = e.point.y - y;

      // 45 needs to be calculated rather
       let rot = Math.round(Math.atan2(yDiff, xDiff) * (180 / Math.PI) + 90);
       if(rot < 0){
         rot += 360;
       }
      this.selectedItem.rotate = Number(rot);
      event.preventDefault();
    }
  }

  // --------------------

  // DELETE

  removeElement() {
    this.$emit('removeElement', this.selectedItem)
    if (this.selectedItem) {
      this.modelValue.items = this.modelValue.items.filter(
        (item: any) => item != this.selectedItem
      );
      this.selectedItem = null;
    }
  }

  // ----------------------------------
  initialHeight =  0;
  initialWidth = 0;
  // SCALE
  scaleStart(event: any) {
    if (!this.selectedItem) {
      return;
    }
    this.dragging = true;
    var e = this.getEvent(event, this.$refs.box as SVGSVGElement);

    // this.bringToTop(this.selectedItem);
    this.dragOffsetX = e.point.x;
    this.dragOffsetY = e.point.y;
    this.controlBoxCenterPt = `${this.dragOffsetX!} ${this.dragOffsetY!}`;

    this.initialWidth = this.selectedItem.width;
    this.initialHeight = this.selectedItem.height;
    

    console.log("SCALE ON");
    (this.$refs.box as any).addEventListener(e.eventName, this.scale);
  }

  scale(event: any) {
    var e = this.getEvent(event, this.$refs.box as SVGSVGElement);

    if (this.selectedItem) {
      
      // var center = this.center(
      //   this.selectedItem,
      //   this.selectedItem.width,
      //   this.selectedItem.height
      // );
      // var centerArr = center.split(" ");

      var x = this.selectedItem.x;
      var y = this.selectedItem.y;
      this.testCenterPt = `${this.selectedItem.y} ${this.selectedItem.x}`;

      var xDiff = e.point.x - this.dragOffsetX!;
      var yDiff = e.point.y - this.dragOffsetY!;

      this.selectedItem.width = Math.round((this.initialWidth * 1 ) +  +xDiff);
      this.selectedItem.height = Math.round((this.initialHeight * 1 ) + +yDiff);
      
      event.preventDefault();
    }
  }

  // ----------------------

  eventStop() {
    this.dragging = false;
    if (!this.selectedItem) {
      return;
    }
    this.dragOffsetX = this.dragOffsetY = null;
    // this.bringToTop(null);
    this.clearEvents();
  }

  clearEvents() {
    (this.$refs.box as any).removeEventListener("touchmove", this.rotate);
    (this.$refs.box as any).removeEventListener("mousemove", this.rotate);
    (this.$refs.box as any).removeEventListener("touchmove", this.move);
    (this.$refs.box as any).removeEventListener("mousemove", this.move);
    (this.$refs.box as any).removeEventListener("touchmove", this.scale);
    (this.$refs.box as any).removeEventListener("mousemove", this.scale);
  }

  drag(event: any) {
    // console.log("drag", this.selectedItem);

    if (!this.selectedItem || (this.selectedItem?.extraId && !this.isAdminOrder)) {
      return;
    }
    this.dragging = true;
    var e = this.getEvent(event, this.$refs.box as SVGSVGElement);

    // this.bringToTop(this.selectedItem);

    this.dragOffsetX = e.point.x - (this.selectedItem.x ?? 0);
    this.dragOffsetY = e.point.y - (this.selectedItem.y ?? 0);

    (this.$refs.box as any).addEventListener(e.eventName, this.move);
  }

  move(event: any) {
    var e = this.getEvent(event, this.$refs.box as SVGSVGElement);
    if (this.selectedItem) {
      this.selectedItem.x =  Math.round(e.point.x - this.dragOffsetX!);
      this.selectedItem.y =  Math.round(e.point.y - this.dragOffsetY!);
      event.preventDefault();
    }
  }
}
