
import { Options, Vue, mixins} from "vue-class-component";
import { UploadPath } from "@/mixins/utilities";

const magicPadding = 10;


@Options({
  props: {
    modelValue: {
      type: Object,
      required: true,
    },
  },
  emits: ["sizeUpdated"]
})
export default class BuilderText extends mixins(UploadPath) {
  modelValue: any;

  mounted(){
    (document as any).fonts.onloadingdone  = () => {
        this.setSize();
    };
    this.$watch("modelValue.fontSize", () => {
      this.setSize();
    });
    this.$watch("modelValue.text", () => {
      this.setSize();
    });
    this.$watch("modelValue.font.fontFileUrl", () => {
      console.log("font Change")
      this.setSize();
    });
    this.setSize();
  }
  get spanX(){
    if(this.modelValue.alignment === "start") {
      return -this.width()/2 ?? 0;
    }
    else if (this.modelValue.alignment === "end") {
      return this.width()/2 ?? 0;
    } else {
      return 0;
    }
  }
  setSize(){
    this.$nextTick(() => {
      const textRef = this.$refs.text as any;

      if(textRef) {
        var height = textRef.getBBox().height ?? 50;
        var width  = textRef.getBBox().width ?? 100;
      }
      
      if(height && width){
        this.$emit('sizeUpdated',{height, width})
      }
    });
  }

  center(){
    return {
      x: this.modelValue.x + this.modelValue.width / 2,
      y: this.modelValue.y + this.modelValue.height / 2,
    };
  }

  width(){
    return this.modelValue.disabled ? null : this.modelValue.width ?? 100
  }

  height(){
    return this.modelValue.disabled ? null : this.modelValue.height ?? 100
  }

// Cause SVG text origin is not the same as all the other things
  textTransformOrigin(){
    if (this.modelValue) {
      // debugger
      if (this.modelValue.height && this.modelValue.width) {
        return `${0} ${(this.modelValue.height / 2) + magicPadding }`;
      }
      return `50 50`;
    }
    return "0 0";
  }

  textTransform() {
    // debugger
    var transformString = "";
    if (this.modelValue.x || this.modelValue.y) {
        transformString += `translate(${this.modelValue.x  } ${(this.modelValue.y - this.height() / 2) - magicPadding})`;
    }

    if (this.modelValue.rotate) {
      transformString += ` rotate(${this.modelValue.rotate})`;
    }
    return transformString;
  }
  
  get splitText() {
    return this.modelValue.text.split("\n");
  }
}
