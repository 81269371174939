
import { Options, mixins } from "vue-class-component";
import { UploadPath } from "@/mixins/utilities";

@Options({
  props: {
    modelValue: {
      type: Object,
      required: true,
    },
    customization: {
      type: Object,
      required: false,
    },
    sectionHeight: {default: 0}
  },
  emits: ['toUpload', 'addArtAsset']
})
export default class ArtAssetPicker extends mixins(UploadPath) {
  assets: Array<any> = [];
  categoryName = '';
  searchTerm = '';
  modelValue: any;
  allAssets: Array<any> = [];
  customization: any;
  sectionHeight = 500

  created() {
    this.getAllAssets()    
  }

  toUpload() {
    this.$emit('toUpload', {
      name: "Upload",
      id: "upload",
      icon: "/img/designer/upload-icon.png",
    })
  }

  get truncatedSearch() {
    if(this.searchTerm.length > 15 ) {
      return this.searchTerm.slice(0, 15).trim() + '...';
    }
    return this.searchTerm.trim();
  }

  get relatedAssets() {
    if (!this.searchTerm) {
      return this.assets;
    } else {
      if(this.assets.length) {
        return this.assets.filter((asset:any) => {
          return asset.name?.toLowerCase().includes(this.searchTerm?.toLowerCase())
            || asset.metaData?.toLowerCase().includes(this.searchTerm?.toLowerCase());
        })
      } else {
        return this.allAssets.filter((asset:any) => {
          return asset.name?.toLowerCase().includes(this.searchTerm?.toLowerCase())
            || asset.metaData?.toLowerCase().includes(this.searchTerm?.toLowerCase());
        })
      }
    }   
  }

  getAllAssets() {
    let allIds = [] as Array<string>;

    this.modelValue.map((category: any) => {
      category.artAssets.map((asset:any) => {           
        if(!allIds.includes(asset.id)) {      
          this.allAssets.push(asset);
        }   
        allIds.push(asset.id)    
      })
    })
  }

  imageObject(artAsset: any) {
    return {
      type: "image",
      x: 340,
      y: 400,
      rotate: 0,
      order: 2,
      height: 100,
      width: 100,
      src: `${artAsset.imageUrl}`,
      customization: this.customization,
      isFromLibrary: true,
      border: {
        width: 0,
        style: 0,
        colour: "",
      }
    };
  }

  selectCategory(category: any) {
    this.categoryName = category.name;
    this.assets = category.artAssets; 
  }
}
