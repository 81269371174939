import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2cd88354"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "col-12 banner-message-container text-uppercase"
}
const _hoisted_2 = ["href"]
const _hoisted_3 = { class: "col-12 bg-white hover-box" }
const _hoisted_4 = { class: "m-0 p-sm-primary text-center col-12" }
const _hoisted_5 = {
  key: 1,
  class: "col-12 bg-white message"
}
const _hoisted_6 = { class: "m-0 p-sm-primary text-center col-12" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.bannerMessages.length && _ctx.showAnyMessagesOnQuote && !_ctx.hideOnPages.includes(_ctx.$route.name))
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.bannerMessages, (bannerMessage) => {
          return (_openBlock(), _createElementBlock(_Fragment, {
            key: bannerMessage.id
          }, [
            (_ctx.showMessageOnQuote(bannerMessage) && bannerMessage.url)
              ? (_openBlock(), _createElementBlock("a", {
                  key: 0,
                  href: bannerMessage.url,
                  target: "_blank",
                  class: "col-12 link message"
                }, [
                  _createElementVNode("div", _hoisted_3, [
                    _createElementVNode("p", _hoisted_4, _toDisplayString(bannerMessage.message), 1)
                  ])
                ], 8, _hoisted_2))
              : (_ctx.showMessageOnQuote(bannerMessage))
                ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                    _createElementVNode("p", _hoisted_6, _toDisplayString(bannerMessage.message), 1)
                  ]))
                : _createCommentVNode("", true)
          ], 64))
        }), 128))
      ]))
    : _createCommentVNode("", true)
}