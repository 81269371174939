
import { Options, Vue } from "vue-class-component";

@Options({
  props: {
    imageFile: {
      type: File,
      required: false,
    },
  },
  emits: ['hasImage', 'filesChanged', 'clear', 'loading']
})
export default class BackgroundReplaceImageUploader extends Vue {

  imgs = "";
  recoverImgUrl = "";
  imageFile: File | null = null;
  imageWidth = 0;
  imageHeight = 0;

  replaceBG = false;
  colour = "#FFFFFF";
  threshold = 20;

  img: HTMLImageElement = new Image();


  imageLoaded() {
    return this.imgs
  }

  pickImage() {
    (this.$refs.filElem as any).dispatchEvent(new MouseEvent("click"));
  }

  getFile() {
    const inputFile = (this.$refs.filElem as any).files[0];
    if (inputFile) {
      (this.$refs.filElem as any).value = "";
      this.drawImage(inputFile);
    } else {
      return;
    }
  }

  async file2DataURI(file: File): Promise<string> {
    return new Promise((resolve, reject) => {
      this.$emit('loading', true)
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = (e) => {
        this.$emit('loading', false)
        resolve(e.target!.result as string);
      };
    });
  }

  async drawImage(inputImage: File) {
    this.$emit('loading', true)
    this.img = new Image()
    this.img.src = await this.file2DataURI(inputImage);
    this.img.onload = () => {
      this.imageWidth = this.img.width;
      this.imageHeight = this.img.height;
      this.$emit('hasImage')
      this.$emit('loading', false)
      this.setImage();
    };
  }

  hexToRgb(hex: string) {
    var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result
      ? {
          r: parseInt(result[1], 16),
          g: parseInt(result[2], 16),
          b: parseInt(result[3], 16),
        }
      : null;
  }

  replaceImageColour(
    ctx: CanvasRenderingContext2D,
    width: number,
    height: number
  ) {
    var imageData = ctx!.getImageData(0, 0, width, height);
    var colourRGB = this.hexToRgb(this.colour);
    let colorMin = {r: Math.max(colourRGB!.r - (this.threshold/2), 0), g: Math.max(colourRGB!.g - (this.threshold/2), 0), b: Math.max(colourRGB!.b - (this.threshold/2), 0)};
    let colorMax = {r: Math.min(colourRGB!.r + (this.threshold/2), 255), g: Math.min(colourRGB!.g + (this.threshold/2), 255), b: Math.min(colourRGB!.b + (this.threshold/2), 255)};

    if (this.replaceBG) {
      for (var i = 0; i < imageData.data.length; i += 4) {
        // is this pixel the old rgb?
        if (

          (colorMin.r <= imageData.data[i]  && imageData.data[i] <= colorMax.r) &&
          (colorMin.g <= imageData.data[i+ 1]  && imageData.data[i+ 1] <= colorMax.g) &&
          (colorMin.b <= imageData.data[i + 2]  && imageData.data[i + 2] <= colorMax.b)

          // imageData.data[i + 1] == colourRGB?.g &&
          // imageData.data[i + 2] == colourRGB?.b
        ) {
          // change to your new rgb
          // imageData.data[i] = 0;
          // imageData.data[i + 1] = 0;
          // imageData.data[i + 2] = 0;
          imageData.data[i + 3] = 0; // <--- this is the alpha channel
        }
      }
    }
    ctx!.putImageData(imageData, 0, 0);
  }

  setTimer : any = null;
  debouceImageSet() {
    if (this.setTimer) {
      clearTimeout(this.setTimer);
    }
    this.setTimer = setTimeout(() => {
      this.setImage();
    }, 25);
  }


  setImage() {
    this.$emit('loading', true)
    let canvas = document.createElement("canvas");
    let ctx = canvas.getContext("2d");
    ctx!.save();
    let width = this.imageWidth = canvas.width = this.img.width;
    let height = this.imageHeight = canvas.height = this.img.height;
    ctx!.drawImage(this.img, 0, 0, width, height);
    this.replaceImageColour(ctx!, width, height);
    this.recoverImgUrl = this.imgs = canvas.toDataURL("image/png");
    this.$emit('loading', false)
    //this.img = new Image()


    // canvas.toBlob(
    //   blob => {
    //     let data = URL.createObjectURL(blob);
    //     URL.revokeObjectURL(this.imgs)
    //     this.imgs = data;

    //     const imgData = canvas.toDataURL();
    //     this.recoverImgUrl = data;
    //   },
    //   "image/" + "png",
    //   1
    // );
  }
}
