
import { Options, mixins } from "vue-class-component";
import CustomizationPicker from "@/features/product-builder/components/editor/CustomizationPicker.vue";
import { UploadPath, IsIOS } from "@/mixins/utilities";

@Options({
  components: {
    CustomizationPicker,
  },
  props: {
    fonts: {
      type: Array,
      default: [],
    },
    customizations: {
      type: Array,
      default: [],
    },
    selectedItem: {
      type: Object,
    },
    quantity: {
      type: Number,
      default: 1,
    },
    isConfigureExtra: {
      type: Boolean,
      default: false
    },
    adminExtraOverride: {
      type: Boolean,
      default: false
    },
    designExtras: {
      type: Array,
      default: [],
    },
  },
  events: ['selectCustomization']
})
export default class TextEditor extends mixins(UploadPath, IsIOS) {
  fonts: Array<any> = [];
  customizations: Array<any> = [];
  selectedItem: any = null;
  quantity = 0;
  usedRandomFonts: Array<any> = []
  iosDropdownDisplay = false
  designExtras: Array<any> = [];
  adminExtraOverride = false;

  textAlignmentOptions = [
    { name: "Start", img: "/img/designer/left-align.png" },
    { name: "Middle", img: "/img/designer/center-align.png" },
    { name: "End", img: "/img/designer/right-align.png" },
  ];

  lastBorder: any = null;

  get borderEnabled(){
    return this.selectedItem.border != null
  }

  created() {
    this.$watch("selectedItem", () => {
      if(this.selectedItem && this.adminExtraOverride) {
        const extra = this.designExtras.find((i: any) => i.id == this.selectedItem.extraId)
        if(extra && this.selectedItem) {
          extra.colour = this.selectedItem.colour;
          extra.font = this.selectedItem.font;
          extra.border = this.selectedItem.border;
        }
      }
    }, { deep: true })
  }

  outsideClickListener(event: any) {
    if (this.iosDropdownDisplay) {
      if (event.target.closest(".ios-font-select") === null) {
        this.fontDropdownToggle()
      }
    }
  }

  fontDropdownToggle() {
    if(this.isIOS) {
      this.iosDropdownDisplay = !this.iosDropdownDisplay
      if(this.iosDropdownDisplay) {
        window.setTimeout(() => {
          document.querySelector(".ios-font-dropdown ul .font-selected")?.scrollIntoView({block: "nearest", inline: "nearest"})
        }, 100)
        window.addEventListener("click", (e) => { this.outsideClickListener(e) })
      }
      else {
        window.removeEventListener("click", (e) => { this.outsideClickListener(e) })
      }
    }
  }

  randomiseFont() {
    let length = this.fonts.length as number
    let randomIndex = Math.floor(Math.random() * (length)) as any;
    if(this.usedRandomFonts.length === length) {
      this.usedRandomFonts = [];
    }

    if(!this.usedRandomFonts.includes(this.fonts[randomIndex])) {
      this.usedRandomFonts.push(this.fonts[randomIndex]);
      this.selectedItem.font = this.fonts[randomIndex];  
    } else {
      this.randomiseFont();
    }
  }

  toggleBorder(){
    if(this.selectedItem.border){
      this.lastBorder = {...this.selectedItem.border};
      this.selectedItem.border = null
    } else {
      if(this.lastBorder){
        this.selectedItem.border = this.lastBorder;
      } else {
        this.selectedItem.border = {
          colour: '#FFFFFF',
          width: 2,
          style: 0
        }
      }
    }
  }

}
