
import { mixins, Options, Vue } from "vue-class-component";
import { store } from "@/store";
import { CalculatePrice, Currency } from "@/mixins/utilities";

@Options({
  props: {
    tileCategory: { type: String, default: "Product Category" },
    tileName: { type: String, default: "Product" },
    tileBgUrl: {
      type: String,
      default: "/img/clifton-product-images-1.1.jpg",
    },
    id: { type: String, default: "" },
    infoLinkTo: { default: { name: "AllCategories" } },
    isCategory: {
      type: Boolean,
      default: false,
    },
    onSale: {
      type: Boolean,
      default: false,
    },
    isNew: {
      type: Boolean,
      default: false,
    },
    priceinfo: {
      type: Object || undefined,
      default: undefined,
    },
    originSlug: {
      type: String,
      default: "",
    },
    productSelector: { default: false },
    storeId: { default: '' },
    isAdminStoreView: { default: false }
  },
})
export default class ProductTile extends mixins(CalculatePrice, Currency) {
  id="";
  storeId="";
  originSlug="";
  tileCategory = "Product Category";
  tileName = "Product";
  tileBgUrl = "/img/clifton-product-images-1.1.jpg";
  infoLinkTo = "AllCategories";
  designLinkTo = "AllCategories";
  isCategory = false;
  onSale = false;
  isNew = false;
  isAdminStoreView = false;

  getFromToPrice(defaultPrice: any, defaultQty: any, minQty: any) {
    if(!defaultPrice) {
      return ``
    }

    return `From ${this.currency().symbol}${this.calculatePrice(defaultPrice, this.currency().pricingMultiplier)} each for ${defaultQty} items | Minimum ${minQty}`
  }

  get toDesigner() {
    return { name: 'Designer', query: { product: this.id, origin: this.originSlug || undefined, store: this.storeId || undefined, adminView: this.isAdminStoreView ? 'true' : undefined }}
  }
}
