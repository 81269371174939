
import { store } from "@/store";
import { mixins, Options } from "vue-class-component";
import SearchBar from "@/components/navigation/search-bar/SearchBar.vue";
import ProductMenu from "@/components/navigation/product-menu/ProductMenu.vue";
import ProductMenuMobile from "@/components/navigation/product-menu/ProductMenuMobile.vue";
import CurrencySelectorMobile from "@/components/navigation/currency-selector/CurrencySelectorMobile.vue";
import CartSummaryProductTile from "@/components/navigation/cart-summary/CartSummaryProductTile.vue";
import NewCurrencySelector from "@/components/navigation/currency-selector/NewCurrencySelector.vue";
import { CalculatePrice, Country, Currency, HandleSlug, IsLoggedIn, QuantityPerItem, ZohoChat, ThousandSeparator, UploadPath } from "@/mixins/utilities";
import { OrderCartDeliveryOptionViewModel, OrderCartItemViewModel, OrderCartViewModel, ProductCategoryMenuGroupViewModel } from "@/api-client";
import "round-flag-icons/css/round-flag-icons.css";
import { Carts, Categories } from "@/network/api";

@Options({
  components: { SearchBar, ProductMenu, ProductMenuMobile, CartSummaryProductTile, CurrencySelectorMobile, NewCurrencySelector },
})
export default class NavBar extends mixins(CalculatePrice, Currency, UploadPath, IsLoggedIn, QuantityPerItem, ThousandSeparator, Country, HandleSlug, ZohoChat) {
  expandCurrencies = false;
  toggleClickOutsideCurrencies = false;
  expandProducts = false;
  toggleClickOutsideProducts = false;
  clickOutsideTimeout: any;
  clearSearch = false;
  cart: Array<OrderCartItemViewModel> = [];
  cartTotal = 0;
  showModal = false;
  allowedRoles = ['Admin', 'Operations', 'Sales', 'Sales Admin']
  productMenuItems: Array<ProductCategoryMenuGroupViewModel> = [];

  get storeCart() {
    return store.getters["checkout/cart"];
  }

  get user() {
    return store.getters["user/profile"];
  }

  get roles() {
    return store.getters["user/role"];
  }

  get cartId() {
    return store.getters["checkout/cartId"];
  }

  get itemsInCart() {
    if(this.storeCart) {
      let totalItems = 0;
      this.storeCart.items.forEach((item: any) => {
        item.quantities.forEach((itemQuanities: any) => {
          totalItems += itemQuanities.quantity;
        })
      });
      if(totalItems <= 9999) {
        return totalItems;
      }
      return '9999+'
    }
    return 0
  }

  get totalPrice() {
    this.cartTotal = 0;
    this.storeCart.items.forEach((item: any) => {
      this.cartTotal += item.price * this.quantityPerItem(item);
    });
    return this.cartTotal;
  }

  get isAdminQuote() {
    return this.storeCart && this.storeCart.isQuote && this.allowedRoles.some(item => this.roles.includes(item));
  }

  get showNav() {
    return !this.isAdminQuote;
  }

  get disableCountry() {
    let disable = false;
    if((['Checkout', 'StoreCart', 'CustomStoreOrder'].includes(this.$route.name as string)) || (this.$route.name === 'Designer' && this.$route.query.store)) {
      disable = true
    }
    return disable;
  }

  created() {
    this.getProductMenuItems()
  }

  mounted() {
    window.onresize = () => this.resetDropDowns();
  }

  getProductMenuItems() {
    Categories.categoriesGetProductCategoryMenuGroupsGet()
    .then((res) => {    
      if(res) {
        this.productMenuItems = res.data.resultData as Array<ProductCategoryMenuGroupViewModel>;
      }
    })
    .catch((error) => {
        console.log(error)
        let errors = error.response.data.errors;
        errors.forEach((error: any) => {
          this.$notify({ type: "error", text: error.friendlyMessage, ignoreDuplicates: true, duration: -1 });
        });
      });
  }

  async exitQuote() {
    await store.dispatch("checkout/refreshCart", {id: null})
    .then(async () => {
      await store.dispatch("checkout/getCart")
      if(this.$route.name == 'Cart' || this.$route.name == 'Designer') {
        this.$router.push({ name: 'Home' });
      }
    })
  }

  handleToEquire() {
    let leadIn = `${window.location.pathname}${window.location.search || ""}`;
    let enquiryCartId = ''
    let savedDesignId = ''

    if(leadIn.includes('/cart')) {
      enquiryCartId = this.cartId;
    }

    if(leadIn.includes('/designer') && this.$route.query['saved-design']) {
      savedDesignId = this.$route.query['saved-design'] as string;
    }

    this.$router.push({ name: 'Enquiries', query: {from: leadIn, cart: enquiryCartId || undefined, design: savedDesignId || undefined} })
  }

  resetDropDowns() {
    if (window.innerWidth >= 992 && document.getElementById("productMenuMobile")) {
      if (document.getElementsByClassName("modal-backdrop")[0]) {
        document.getElementById("productMenuMobile")!.click();
      }
    }

    if (window.innerWidth >= 992 && document.getElementById("currencySelectorMobile")) {
      if (document.getElementsByClassName("modal-backdrop")[0]) {
        document.getElementById("currencySelectorMobile")!.click();
      }
    }

    if (window.innerWidth < 992 && this.expandProducts) {
      this.expandProducts = false;
    }

    if (window.innerWidth < 992 && this.showModal) {
      this.showModal = false;
    }
  }

  updated() {
    if (this.expandCurrencies || this.expandProducts) {
      let mobile = document.getElementById("navbarCollapse");
      let currency = document.getElementById("currency-dropdown");
      let product = document.getElementById("product-dropdown");

      if (mobile?.classList.contains("show")) {
        mobile?.classList.remove("show");
      }

      if (!currency?.classList.contains("menu-dropdown-open")) {
        this.expandCurrencies = false;
      }

      if (!product?.classList.contains("menu-dropdown-open")) {
        this.expandProducts = false;
      }
    }
  }

  async selectCountry(country: any) {
    await store.dispatch("location/changeLocation", { country });
    if(this.cartId && this.storeCart && (this.$route.name !== 'Cart')) {
      if(this.storeCart) {
        await this.getDefaultDelivery(country)
      }
    }
    this.expandCurrencies = false;
  }

  async getDeliveryOptions(country:any) {
    return new Promise<Array<OrderCartDeliveryOptionViewModel>>((resolve, reject) => {
    if(this.cartId) {
      let accessKey = (this.$route.params.accessKey as string) || undefined;
      Carts.cartsGetCartDeliveryOptionsIdGet(this.cartId, country.id, accessKey)
        .then((res) => {
          if (res.data.succeeded) {
            resolve(res.data.resultData as Array<OrderCartDeliveryOptionViewModel>);
          }
        })
        .catch((error) => {
          console.log(error)
          let errors = error.response.data.errors;
          errors.forEach((error: any) => {
            this.$notify({ type: "error", text: error.friendlyMessage, ignoreDuplicates: true, duration: -1 });
          });
          reject(error)
        });
      }
    })
  }

  async updateCart(updatedCart:OrderCartViewModel) {
    let accessKey = (this.$route.params.accessKey as string) || undefined;
    await Carts.cartsUpdateCartIdPut(updatedCart.id, accessKey, updatedCart)
      .catch((error) => {
        console.log(error);
        let errors = error.response.data.errors;
        errors.forEach((error: any) => {
          this.$notify({ type: "error", text: error.friendlyMessage, ignoreDuplicates: true, duration: -1 });
        });
      });
  }

  async getDefaultDelivery(country:any) {
    let selectedDeliveryType:any = null
    const cartDelivery = this.storeCart.deliveryTypeId
    
    await this.getDeliveryOptions(country)
    .then(async (deliveryOptions) => {
      if (deliveryOptions.length) {
        let typeAvailable = deliveryOptions.filter((x) => x.deliveryTypeId === this.storeCart.deliveryTypeId).length > 0;
        if (typeAvailable) {
          deliveryOptions.forEach((type: OrderCartDeliveryOptionViewModel) => {
            if (this.storeCart.deliveryTypeId === type.deliveryTypeId) {
              selectedDeliveryType = type;
            }
          });
        }

        if ((!typeAvailable || cartDelivery === "") && deliveryOptions.length > 0) {
          selectedDeliveryType = deliveryOptions[0];
        }
      }
      let updatedCart: OrderCartViewModel = {...this.storeCart}
      updatedCart.deliveryTypeId = selectedDeliveryType.deliveryTypeId,
      updatedCart.countryId = country.id
      updatedCart.currencyId = country.currency.id
      await this.updateCart(updatedCart)
    });
  }

  toggleNavbar({target}:any) {
    let targetParent = target.offsetParent
    let hidden = target.classList.contains("d-none")

    if(this.expandProducts && (!targetParent || (targetParent?.id !== 'product-dropdown') || hidden)) {
      this.expandProducts = false;
    }
    let searchNav = document.getElementById("searchModal");
    let nav = document.getElementById("navbarCollapse");
    if (!searchNav?.classList.contains("show")) {
      nav?.classList.remove("show");
      this.clearSearch = true;
    }
  }

  async logout() {
    await store.dispatch("auth/logout");
    this.$notify({ type: "success", text:  `Successfully logged out.` });
    const authedPages = ['CustomStoreAdminStore', 'Profile', 'Cart', 'Checkout', 'PaymentComplete', 'OutstandingPaymentComplete', 'EFTPaymentPage', 'OrderPayments', 'ResupplyAssets']
    if(authedPages.includes(this.$route.name as string)) {
      this.$router.push({ name: 'Home' });
    }
  }
}
