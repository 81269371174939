
import { ref } from "vue";
import { Options, Vue } from "vue-class-component";

@Options({
  props: {
    font: {
      type: Object,
      required: true,
    },
    customization: {
      type: Object,
      required: false,
    },
    sectionHeight: {default: 0}
  },
  events: ["addText", 'setSize'],
})
export default class TextAdd extends Vue {
  editorText = "";
  font: any;
  customization: any;
  sectionHeight= 500;
  textObject(text: string) {
    return {
      type: "text",
      x: 340,
      y: 400,
      colour: "#000000",
      rotate: 0,
      fontSize: 60,
      order: 2,
      text: text,
      font: this.font,
      customization: this.customization,
      fontWeight: "normal",
      fontStyle: "normal",
      textSvgUrl: '',
      alignment: "middle",
      isFromLibrary: true,
      border: null
    };
  }

  addText() {
    if(this.editorText !== '') {
      this.$emit('addText', this.textObject(this.editorText))
      this.editorText = ''
    }    
  }
}
