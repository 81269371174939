
import { Options, Vue } from "vue-class-component";

@Options({
  components: {},
  props: {
    menuItems: { default: null }
  },
})
export default class ProdcutMenuMobile extends Vue {
}
