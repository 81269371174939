
import { Options, Vue } from "vue-class-component";

@Options({
  props: { 
    type: { default: '' },
    location: { default: null }
  },
  emits: ['getLocation', 'openTools']
})
export default class SelectedItemTrigger extends Vue {
  flags: any = false;
  position: any = {
    x: 0,
    y: 0
  };
  nx: any = ''
  ny: any = ''
  dx: any = ''
  dy: any = ''
  xPum: any = 40
  yPum: any = 130
  moveDiv:any = ''

  mounted() {
    this.moveDiv = document.getElementById('bubble');
  }

  openTools() {
    this.$emit('openTools')
  }


  // Mobile drag
  down(e:any){
    this.flags = true;
    var touch ;
    if(e.touches){
        touch = e.touches[0];
    }else {
        touch = e;
    }
    this.position.x = touch.clientX;
    this.position.y = touch.clientY;
    this.dx = this.moveDiv.offsetLeft;
    this.dy = this.moveDiv.offsetTop;
  }
  move(e:any){
    if(this.flags){
      var touch ;
      if(e.touches){
          touch = e.touches[0];
      }else {
        touch = e;
      }
      this.nx = touch.clientX - this.position.x;
      this.ny = touch.clientY - this.position.y;
      this.xPum = this.dx + this.nx;
      this.yPum = this.dy + this.ny;
      // this.moveDiv.style.left = this.xPum+"px";
      // this.moveDiv.style.top = this.yPum +"px";
      this.$emit('getLocation', {top: this.yPum, left: this.xPum})
      //Prevent page from sliding default events
      document.addEventListener("touchmove",function(){
          e.preventDefault();
      },false);
    }
  }
//Function when mouse is released
  end(){
    this.flags = false;
  }
  
}
