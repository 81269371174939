
import { Options, mixins } from "vue-class-component";
import { CalculatePrice, Currency, UploadPath } from "@/mixins/utilities";

@Options({
  props: {
    elements: {
      type: Object,
      required: true,
    },
    selected: { default: null },
    imgPath: { type: String },
    comparisonType: { 
      type: String,
      default: 'equals'
    },
    quantity: {
      default: null
    }
  },
  emits: ['click']
})
export default class ElementTile extends mixins(UploadPath, Currency, CalculatePrice) {
  comparisonType = 'equals';
  quantity = null;
  selected = [];
  
  handleClick(element: any) {
    this.$emit('click', element)
  }

  includesItem(itemId: string ) {
    return this.selected.some((e: any) => e.id === itemId);
  }

  correctSymbol(prices:any) {
    const price = this.calculatePrice(this.getPriceByQuantity(prices) as number, this.currency().pricingMultiplier);
    return +price < 0 ? '-' : '+';
  }

  getPriceByQuantity(prices:any) {   
    if(this.quantity && prices.length) {
      let price = 0;
      var range = prices.filter((x:any) => (x.minimumQuantity ?? Number.MIN_SAFE_INTEGER) <= this.quantity! && (x.maximumQuantity ?? Number.MAX_SAFE_INTEGER) >= this.quantity!)[0];
      if(range != null)
      {
        price += range.price;
      }
      return price;
    }
  }
}
