import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_metainfo = _resolveComponent("metainfo")!
  const _component_notifications = _resolveComponent("notifications")!
  const _component_NavBar = _resolveComponent("NavBar")!
  const _component_BannerMessage = _resolveComponent("BannerMessage")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_Footer = _resolveComponent("Footer")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_metainfo, null, {
      title: _withCtx(({ content }) => [
        _createTextVNode(_toDisplayString(content ? `${content}` : `CLIFTON | Truly Custom Clothing`), 1)
      ]),
      description: _withCtx(({ content }) => [
        _createTextVNode(_toDisplayString(content ? `${content}` : `We make it easy for organisations, clubs, and groups of all sizes to choose, design & order truly custom clothing.`), 1)
      ]),
      keywords: _withCtx(({ content }) => [
        _createTextVNode(_toDisplayString(content ? `${content}` : `customapparel, customclothing, custom tees, custom shirts, custom tshirts, tshirt, tshirts, fashion, custom tshirt, sublimation, custom, screenprinting, clothing, custom tee, custom apparel, custom tee shirts`), 1)
      ]),
      "og(title)": _withCtx(({ content }) => [
        _createTextVNode(_toDisplayString(content ? `${content}` : `CLIFTON | Truly Custom Clothing`), 1)
      ]),
      "og(description)": _withCtx(({ content }) => [
        _createTextVNode(_toDisplayString(content ? `${content}` : `We make it easy for organisations, clubs, and groups of all sizes to choose, design & order truly custom clothing.`), 1)
      ]),
      "og(type)": _withCtx(({ content }) => [
        _createTextVNode(_toDisplayString(content ? `${content}` : `website`), 1)
      ]),
      "og(url)": _withCtx(({ content }) => [
        _createTextVNode(_toDisplayString(content ? `${content}` : `https://www.cliftonclothing.com`), 1)
      ]),
      _: 1
    }),
    _createVNode(_component_notifications, { position: "top center" }),
    (_ctx.showNav)
      ? (_openBlock(), _createBlock(_component_NavBar, { key: 0 }))
      : _createCommentVNode("", true),
    _createVNode(_component_BannerMessage),
    _createVNode(_component_router_view),
    (_ctx.$route.name !== 'Designer')
      ? (_openBlock(), _createBlock(_component_Footer, { key: 1 }))
      : _createCommentVNode("", true)
  ], 64))
}