import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "prod-select-category-box-info col-12" }
const _hoisted_2 = { class: "text-center primary-font-bold col-11" }
const _hoisted_3 = { class: "text-center col-11" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    onClick: _cache[0] || (_cache[0] = ($event: any) => (this.$emit('clearArrays'))),
    class: "prod-select-category-box m-0 p-0 col-6 col-md-4 col-xl-3 d-flex flex-column justify-content-end align-items-center",
    style: _normalizeStyle({
      'background-image': `linear-gradient(to bottom,rgba(255, 255, 255, 0),rgba(0, 0, 0, 0.1),rgba(0, 0, 0, 0.9)),url('${_ctx.categoryBgUrl}')`,
    })
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("h3", _hoisted_2, _toDisplayString(_ctx.category), 1),
      _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.productInfo), 1)
    ])
  ], 4))
}