
import { ControlSection } from "@/types";
import { PropType } from "vue";
import { Options, Vue } from "vue-class-component";
import DesignerMobileNav from './DesignerMobileNav.vue'

@Options({
  components: { DesignerMobileNav },
  props: {
    sectionArray: {
      default: [],
    },
    selectedSection: {
      type: Object as PropType<ControlSection>, 
      required: true
    },
    hasSelectedItem: {
      default: false,
    },
    designing: {
      default: false,
    },
    hasFeatures: {
      default: true,
    },
    hasText: {
      default: true,
    },
    hasArtwork: {
      default: true,
    },
    styleArrow: {
      default: false
    },
    isAdminOrder: {
      default: false
    },
    isConfigureExtra: {
      default: false
    }
  },
  emits: ['toggleControls', 'handleClick']
})
export default class ControlSections extends Vue {
  showControls = true;
  designing = false;
  hasFeatures = true;
  hasText = true;
  hasArtwork = true;
  isConfigureExtra = false;

  handleDisable(section: ControlSection) {
    const emptyFeature = (section.id === 'features') && !this.hasFeatures;
    const emptyImageApplication = (section.id === 'art' || section.id === 'upload') && !this.hasArtwork;
    const emptyTextApplication = section.id === 'text' && !this.hasText;
    const configureExtraDisabled = this.isConfigureExtra && section.disabledOnConfigureExtra;
    return configureExtraDisabled || emptyFeature || emptyImageApplication || emptyTextApplication;
  }

  toggleControls() {
    this.showControls = !this.showControls;
    this.$emit('toggleControls')
  }
}
