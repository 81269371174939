import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createVNode as _createVNode, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "col-12 d-flex flex-column p-0 m-0" }
const _hoisted_2 = { class: "h-lg-secondary text-center my-3 text-uppercase" }
const _hoisted_3 = {
  key: 0,
  class: "col-12 d-flex flex-column m-0 p-0"
}
const _hoisted_4 = { class: "row m-0 p-0" }
const _hoisted_5 = { class: "col-12 flex-centered" }
const _hoisted_6 = { ref: "bottom" }
const _hoisted_7 = { class: "col-12" }
const _hoisted_8 = {
  key: 0,
  class: "col-12",
  style: {"display":"flex","flex-direction":"row","flex-wrap":"wrap"}
}
const _hoisted_9 = { class: "col-12 flex-centered" }
const _hoisted_10 = { ref: "bottom" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ProductSelectorCategoryTile = _resolveComponent("ProductSelectorCategoryTile")!
  const _component_Spinner = _resolveComponent("Spinner")!
  const _component_ProductSelectorProductTile = _resolveComponent("ProductSelectorProductTile")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, "Select a " + _toDisplayString(_ctx.products.length ? 'Product' : 'Category'), 1),
    (!_ctx.products.length)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.categories, (category) => {
              return (_openBlock(), _createBlock(_component_ProductSelectorCategoryTile, {
                key: category.id,
                category: category.name,
                productInfo: '',
                categoryBgUrl: _ctx.getImage(category),
                onClick: ($event: any) => (_ctx.selectCategory(category)),
                onClearArrays: _ctx.clearArrays
              }, null, 8, ["category", "categoryBgUrl", "onClick", "onClearArrays"]))
            }), 128)),
            _createElementVNode("div", _hoisted_5, [
              _createVNode(_component_Spinner, { visible: _ctx.loading }, null, 8, ["visible"])
            ])
          ]),
          _createElementVNode("span", _hoisted_6, null, 512)
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_7, [
      (_ctx.products.length)
        ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.products, (product) => {
              return (_openBlock(), _createBlock(_component_ProductSelectorProductTile, {
                key: product.id,
                tileName: product.name,
                id: product.id,
                priceinfo: {defaultQuantityPrice: product.defaultQuantityPrice, defaultQuantity: product.defaultQuantity, minimumQuantity: product.minimumQuantity},
                tileCategory: '',
                tileBgUrl: _ctx.getImage(product),
                onClick: ($event: any) => (_ctx.$emit('productSelected', product.slug))
              }, null, 8, ["tileName", "id", "priceinfo", "tileBgUrl", "onClick"]))
            }), 128)),
            _createElementVNode("div", _hoisted_9, [
              _createVNode(_component_Spinner, { visible: _ctx.loading }, null, 8, ["visible"])
            ])
          ]))
        : _createCommentVNode("", true),
      _createElementVNode("span", _hoisted_10, null, 512)
    ])
  ]))
}