
export default {  
  name: 'TermlyPolicy',  
  mounted() {  
    // Remove any existing Termly script to avoid duplicates  
    const existingScript = document.getElementById('termly-jssdk');  
    existingScript?.parentNode?.removeChild(existingScript);
  
    // Create a new script element  
    const script = document.createElement('script');  
    script.id = 'termly-jssdk';  
    script.src = "https://app.termly.io/embed-policy.min.js";    
    document.body.appendChild(script);  
  }  
}  
