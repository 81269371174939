import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "col-12 common-actions-container pt-2" }
const _hoisted_2 = ["onClick"]
const _hoisted_3 = { class: "actions col-12 d-flex flex-column align-items-center justify-content-evenly" }
const _hoisted_4 = { class: "action-img flex-centered col-12" }
const _hoisted_5 = ["src"]
const _hoisted_6 = { class: "action-text flex-centered col-12" }
const _hoisted_7 = { class: "m-0 p-0 designer-section-title-sm text-center" }
const _hoisted_8 = {
  key: 0,
  style: {"width":"20%"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: _normalizeClass(["col-12 d-flex", { 'justify-content-around' : !_ctx.isConfigureExtra }])
    }, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.actions, (action) => {
        return (_openBlock(), _createElementBlock(_Fragment, {
          key: action.name
        }, [
          (_ctx.handleDisable(action))
            ? (_openBlock(), _createElementBlock("div", {
                key: 0,
                style: {"width":"20%"},
                class: "action-container p-1",
                onClick: ($event: any) => (_ctx.$emit(action.emit, action.layer && action.layer))
              }, [
                _createElementVNode("div", _hoisted_3, [
                  _createElementVNode("div", _hoisted_4, [
                    _createElementVNode("img", {
                      src: action.imageSrc
                    }, null, 8, _hoisted_5)
                  ]),
                  _createElementVNode("div", _hoisted_6, [
                    _createElementVNode("p", _hoisted_7, _toDisplayString(action.name), 1)
                  ])
                ])
              ], 8, _hoisted_2))
            : _createCommentVNode("", true)
        ], 64))
      }), 128)),
      (_ctx.adminExtraOverride)
        ? (_openBlock(), _createElementBlock("div", _hoisted_8))
        : _createCommentVNode("", true)
    ], 2)
  ]))
}