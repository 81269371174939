
import { Country } from "@/mixins/utilities";
import { BannerMessages } from "@/network/api";
import { mixins, Options, Vue } from "vue-class-component";

type BannerMessageViewModel = {
  id: string;
  countryId: string;
  countryName: string;
  startDate: Date | string;
  endDate: Date | string;
  url: string | null;
  message: string;
  showOnQuotes: boolean;
};

@Options({
})
export default class BannerMessage extends mixins(Country) {
  bannerMessages: Array<BannerMessageViewModel> = [];
  hideOnPages: string[] = ['Designer', 'Login', 'Register', 'ForgotPassword', 'EmailVerification', 'StoreCart', 'PrintQuote', 'PaymentComplete', 'OutstandingPaymentComplete', 'EFTPaymentPage', 'OrderPayments', 'CustomStoreCustomerPaymentComplete', 'CustomStoreAdminStore', 'CustomStoreOrder', 'CheckOrderStatus', 'ResupplyAssets', 'PageNotFound'];

  get showAnyMessagesOnQuote() {
    if(this.$route.name === 'Quote') {
      let hideCount = this.bannerMessages.length;
      this.bannerMessages.forEach((item) => {
        if(!item.showOnQuotes) {
          hideCount -= 1;
        }
      })
      return hideCount == 0 ? false : true;
    }
    return true
  }

  get watchCountry() {
    return this.country();
  }

  created() {
    this.loadInitialData();

    this.$watch("watchCountry", () => {
      this.loadInitialData();
    })
  }

  showMessageOnQuote(item:BannerMessageViewModel) {
    if(this.$route.name === "Quote" && !item.showOnQuotes) {
      return false;
    }
    return true;
  } 

  loadInitialData() {
    if(this.country()?.id) {
      BannerMessages.bannerMessagesGet(this.country().id)
      .then((res) => {
        if(res) {
          this.bannerMessages = res.data.resultData as Array<BannerMessageViewModel>;
        }
      })
      .catch((error) => {
        console.log(error);
        let errors = error.response.data.errors;
        errors.forEach((error: any) => {
          this.$notify({ type: "error", text: error.friendlyMessage, ignoreDuplicates: true, duration: -1 });
        });
      });
    }
  }
}
