
import { DeliveryType, OrderCartViewModel, OrderStatus } from "@/api-client";
import { EnableSave } from "@/types";
import { Options, Vue } from "vue-class-component";

@Options({
  props: {
    cart: {
      default: null
    },
    isAdminOrder: {
      default: false
    },
    enableSave: {
      default: false
    },
    isInCurrentCart: {
      default: null
    },
    storeId: {
      default: null
    },
    isInStore: { default: false },
    enableConfigureExtraSave: {
      default: false
    },
  },
  emits: ['checkout', 'addToCart', 'save', 'return', 'update', 'addToStore']
})
export default class PurchaseOptions extends Vue {
  cart: OrderCartViewModel = {
    id: "",
    items: [],
    countryId: "",
    isGift: false,
    deliveryTypeId: DeliveryType.Standard,
    orderStatusId: OrderStatus.Cart,
    currencyId: "",
    currencyPricingMultiplyer: 1,
    requiresCustomerApproval: true,
    additionalCostsConvertedTotal: 0,
    orderDocuments: [],
    isQuote: false,
    referenceNumber: '',
    deliveryAddress: {
      id: "",
      addressLine1: "",
      area: "",
      country: "",
      postalCode: "",
    },
    billingAddress: {
      id: "",
      addressLine1: "",
      area: "",
      country: "",
      postalCode: "",
    },
    customerContact: {
      id: "",
      name: "",
      email: "",
      phoneNumber: "",
    },
    accessKey: '',
    allowedPaymentProviders: [],
    paymentsTotal: 0,
    paymentsConvertedTotal: 0,
    customerContacts: []
  };
  isInCurrentCart = false
  enableSave: EnableSave = {
    customer: undefined, admin: undefined
  }

  get buyButtonText() {
    if(this.cart && this.cart.isQuote) {
      if(this.isInCurrentCart && this.enableSave.admin) {
        return 'Update Quote'
      }
      return 'Quote'
    }  
    
    if (this.isInCurrentCart && this.enableSave.customer) {
      return 'Update Cart'
    }

    return null;
  }
}
