
import { ProductListViewModel, ProductListViewModelPaginatedList } from "@/api-client";
import { Products } from "@/network/api";
import GlobalSearchResultTile from "@/components/navigation/search-bar/GlobalSearchResultTile.vue";
import { mixins, Options, Vue } from "vue-class-component";
import { UploadPath } from "@/mixins/utilities";
import { ref } from "@vue/reactivity";
import Spinner from "@/components/spinner/Spinner.vue";

@Options({
  components: { GlobalSearchResultTile, Spinner },
  props: {
    clearSearch: { default: false }
  },
  emits: ['reset']
})
export default class SearchBar extends mixins(UploadPath) {
  searchTerm = "";
  searchedProductPages: ProductListViewModelPaginatedList = {
    hasNextPage: false,
    hasPreviousPage: false,
    items: [],
    pageIndex: 1,
    totalCount: 15,
    totalPages: 1,
    pageSize: 15,
  };
  displayedSearchItems: Array<ProductListViewModel> = [];
  pageIndex = 1;
  loading = false;
  debounce: any = null;
  clearSearch = false;
  modal = ref()
  searchModal:any;

  created() {
    this.$watch("searchTerm", (v: any) => {
      this.pageIndex = 1;
      this.displayedSearchItems = [];
      this.loading = false;

      clearTimeout(this.debounce);
      if (this.searchTerm) {
        this.searchProductPages()
      }
    });

    this.$watch("clearSearch", () => {
      if(this.clearSearch) {
        this.searchTerm = "";
        this.$emit('reset')
      }
    })
  }

  mounted() {
    this.searchModal = document.getElementById('searchModal')
    if(this.searchModal) {
      this.searchModal.addEventListener('shown.bs.modal', () => this.focusInput())
    }
    this.scroll();
  }

  unmounted() {
    this.searchModal.removeEventListener('shown.bs.modal', () => this.focusInput())
  }

  isScrolledIntoView(el: any) {
    let rect = el.getBoundingClientRect() as DOMRect;
    let elemTop = rect.top;
    let elemBottom = rect.bottom;
    let isVisible = elemTop < window.innerHeight && elemBottom >= 0;
    return isVisible;
  }

  scroll() {
    let allRefs = this.$refs as any;
    allRefs.results.onscroll = () => {
      let scrolledTo = allRefs.bottom;
      

      if (scrolledTo && this.isScrolledIntoView(scrolledTo)) {
        this.loadMoreProducts();
      }
    };
  }

  focusInput() {
    let refs = this.$refs as any;
    let search = refs.search;    
    search.focus();
  }

  loadMoreProducts() {
    if ((this.loading === false) && (this.searchedProductPages.hasNextPage === true)) {
      this.loading = true;
      this.pageIndex += 1;
      
      this.searchProductPages(this.pageIndex);
    }
  }

  async searchProductPages(page = this.pageIndex) {
      if (this.searchTerm) {
        this.loading = true;
        this.debounce = setTimeout(async () => {
          await Products.productsSearchGet(page, 12, this.searchTerm)
            .then((res) => {
              if (res.data.resultData) {
                this.searchedProductPages = res.data.resultData;
                this.searchedProductPages.items.forEach((item:any) => {
                  //this is for lazy loading
                  this.displayedSearchItems.push(item);
                });
                this.loading = false;
              }
            })
            .catch((error) => {
console.log(error)
              this.loading = false;
              clearTimeout(this.debounce);
              let errors = error.response.data.errors;
              errors.forEach((error: any) => {
                this.$notify({ type: "error", text: error.friendlyMessage, ignoreDuplicates: true, duration: -1 });
              });
            });
        }, 400);
      } else {
        this.displayedSearchItems = [];
        this.loading = false;
      }
  }

  beforeUnmount() {
    clearTimeout(this.debounce);
  }

}
