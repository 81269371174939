
import { Options, mixins } from "vue-class-component";
import { UploadPath } from "@/mixins/utilities";
import ElementTile from "./ElementTile.vue";

@Options({
  components: { ElementTile },
  props: {
    features: {
      type: Object,
      required: true,
    },
    selectedFeatures: {
      type: Array,
      default: [],
    },
    quantity: {
      default: null
    },
    sectionHeight: { default: 0 },
  },
})
export default class FeaturesPicker extends mixins(UploadPath) {
  features: Array<any> = [];
  sectionHeight = 500
  searchTerm = "";

  get relatedFeatures() {
    if (!this.searchTerm) {
      return this.features;
    } else {
      return this.features.filter((feature:any) => {
        return feature.name?.toLowerCase().includes(this.searchTerm?.toLowerCase());
      })
    }   
  }
}
