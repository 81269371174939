
import { CategoryListViewModel, CategoryListViewModelPaginatedList, CategoryViewModel, ProductListViewModelPaginatedList, ProductViewModel } from "@/api-client";
import { Categories, Products } from "@/network/api";
import { Options, mixins } from "vue-class-component";
import ProductSelectorCategoryTile from './ProductSelectorCategoryTile.vue'
import ProductSelectorProductTile from './ProductSelectorProductTile.vue'
import Spinner from "@/components/spinner/Spinner.vue";

import { UploadPath } from "@/mixins/utilities";

@Options({
  components: { ProductSelectorCategoryTile, ProductSelectorProductTile, Spinner },
  beforeRouteUpdate(to, from) {
    this.clearArrays();
  },
  beforeRouteLeave(to, from) {
    this.clearArrays();
  },
  //   props: {
  //     modelValue: {
  //       type: Object,
  //       required: true,
  //     },
  //   },
})
export default class ProductSelector extends mixins(UploadPath) {
  categories: Array<CategoryViewModel> = [];
  category: CategoryViewModel | null = null;
  products: Array<any> = [];
  categoryPages: CategoryListViewModelPaginatedList = {
    hasNextPage: false,
    hasPreviousPage: false,
    items: [],
    pageIndex: 1,
    totalCount: 1,
    totalPages: 1,
    pageSize: 12,
  };
  productPages: ProductListViewModelPaginatedList = {
    hasNextPage: false,
    hasPreviousPage: false,
    items: [],
    pageIndex: 1,
    totalCount: 1,
    totalPages: 1,
    pageSize: 12,
  };
  loading = true;
  pageIndex = 1;

  // remove these later maybe
  bannerImage = "";
  title = "";

  mounted() {
    this.loadAllCategories();
    this.scroll();
  }

  clearArrays() {
    //empty all arrays for lazy loading
    this.category = null;
    this.pageIndex = 1;
    this.categories = [];
    this.products = [];
    this.loading = true;
    this.categoryPages = {
      hasNextPage: false,
      hasPreviousPage: false,
      items: [],
      pageIndex: 1,
      totalCount: 1,
      totalPages: 1,
      pageSize: 12,
    };
    this.productPages = {
      hasNextPage: false,
      hasPreviousPage: false,
      items: [],
      pageIndex: 1,
      totalCount: 1,
      totalPages: 1,
      pageSize: 12,
    };
  }

  selectCategory(category: CategoryViewModel) {
    this.category = category;
    this.loadNestedCategories(category.fullSlug);
  }

  getImage(item: CategoryViewModel) {
    if (item.thumbnailImages.length > 0) {
      return `${this.uploadPath}/${item.thumbnailImages[0].imageUrl}`;
    }
    return ``;
  }

  isScrolledIntoView(el: any) {
    let rect = el.getBoundingClientRect() as DOMRect;
    let elemTop = rect.top;
    let elemBottom = rect.bottom;
    let isVisible = elemTop == window.innerHeight && elemBottom == window.innerHeight;
    
    return isVisible;
  }

  scroll() {
    window.onscroll = () => {
      let allRefs = this.$refs as any;
      let scrolledTo = allRefs.bottom;
      console.log('scroll?', scrolledTo);
      

      if (scrolledTo && this.isScrolledIntoView(scrolledTo)) {
        this.loadMoreItems();
      }
    };
  }

  loadMoreItems() {
    console.log(this.categoryPages, this.category);
    
    if (this.loading === false && (this.categoryPages.hasNextPage === true || this.productPages.hasNextPage === true)) {
      this.loading = true;
      this.pageIndex += 1;
      console.log("adding 1 to index");

      if(this.category) {
        this.loadNestedCategories(this.category.fullSlug);
      } else {
        this.loadAllCategories();
      }   
    }
  }

  loadAllCategories() {
    Categories.categoriesGet(this.pageIndex, 12)
      .then((res) => {
        if (res.data.succeeded) {
          this.categoryPages = res.data.resultData as CategoryListViewModelPaginatedList;
          this.categoryPages.items.map((item: any) => {
            this.categories.push(item);
          });
          this.loading = false;
        }
      })
      .catch((error) => {
console.log(error)
        this.loading = false;
        let errors = error.response.data.errors;
        errors.forEach((error: any) => {
          this.$notify({ type: "error", text: error.friendlyMessage, ignoreDuplicates: true, duration: -1 });
        });
      });
  }

  loadNestedCategories(slug: string) {
    console.log('single cat', this.category, slug);
    console.log('slug', slug);
    
    if (this.category!.hasProducts) {
      console.log("we have products here");
      // this.getFilterTags();
      this.getProducts(slug);
    } else {
      Categories.categoriesParentCategorySlugGet(this.pageIndex, 12, slug)
      .then((res: any) => {
        this.categoryPages = res.data.resultData;
        this.categoryPages.items.map((item: any) => {
          this.categories.push(item);
        });
        console.log('cat', this.categories);  
        this.loading = false;          
      })
      .catch((error) => {
console.log(error)
        this.loading = false;
        let errors = error.response.data.errors;
        errors.forEach((error: any) => {
          this.$notify({ type: "error", text: error.friendlyMessage, ignoreDuplicates: true, duration: -1 });
        });
      });
    }
  }

  getProducts(slug: string) {
    Products.productsParentCategorySlugGet(this.pageIndex, 12, slug)
      .then((res) => {
        this.productPages = res.data.resultData as ProductListViewModelPaginatedList;
        this.productPages.items.map((item) => {
          this.products.push(item);
        });
        this.loading = false;
      })
      .catch((error) => {
console.log(error)
        this.loading = false;
        let errors = error.response.data.errors;
        errors.forEach((error: any) => {
          this.$notify({ type: "error", text: error.friendlyMessage, ignoreDuplicates: true, duration: -1 });
        });
      });
  }
}
