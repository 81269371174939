
import { Options, mixins } from "vue-class-component";
import { UploadPath } from "@/mixins/utilities";

@Options({
  props: {
    modelValue: {
      type: Object,
      required: true,
    },
  },
})
export default class BuilderImage extends mixins(UploadPath) {
  modelValue: any;

  get width(){
    return this.modelValue.disabled ? null : this.modelValue.width ?? 100
  }

  get height(){
    return this.modelValue.disabled ? null : this.modelValue.height ?? 100
  }

  get imageTransformOrigin() {
    if (this.modelValue) {
      if (this.modelValue.height && this.modelValue.width) {
        return `${this.modelValue.width / 2} ${this.modelValue.height / 2 }`;
      }
      return `50 50`;
    }
    return "0 0";
  }

  get imageTransform() {
    var transformString = "";
    if (this.modelValue) {
      if (this.modelValue.x || this.modelValue.y) {
        transformString += `translate(${this.modelValue.x - this.width / 2 } ${this.modelValue.y - this.height / 2})`;
      }
      if (this.modelValue.rotate) {
        transformString += ` rotate(${this.modelValue.rotate})`;
      }
      if (this.modelValue.scale) {
        transformString += ` scale(${this.modelValue.scale})`;
      }
    }
    return transformString;
  }

}
