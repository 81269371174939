
import { mixins, Options } from "vue-class-component";
import { LoadImage } from "@/mixins/utilities";

@Options({
  props: { 
    bannerSrc: { default: '/img/about-us/about-us-banner-thin-s.jpg'},
    title: { default: ''},
    short: {default: false},
    position: {default: 'center top'},
    smallerText: {default: false},
  }
})
export default class HeadingBanner extends mixins(LoadImage) {
  banner: any = {};
  bannerSrc = '/img/about-us/about-us-banner-thin-s.jpg';

  created() {
    this.loadImage(this.bannerSrc)
    .then((img : any) => this.banner = img)
    .catch(err => console.error(err));
  }
}
