
import { mixins, Options, Vue } from "vue-class-component";
import CurrencyGroup from "@/components/navigation/currency-selector/CurrencyGroup.vue";
import { CountryViewModel } from "@/api-client";
import { Countries } from "@/network/api";
import { Country } from "@/mixins/utilities";
import { store } from "@/store";

@Options({
  components: { CurrencyGroup },
  emits: ["select"]
})
export default class CurrencySelectorMobile extends mixins(Country) {
  allCountries: Array<any> = [];
  selectedCountry: any = {};

  created() {
    this.loadInitialData()

    this.$watch('countryWatcher', () => {
      this.selectedCountry = this.country().id;
    })
  }

  get countryWatcher() {
    return store.getters["location/country"];
  }

  mounted() {
    this.selectedCountry = this.country().id;
  }

  // get sortedCountries() {
  //   return this.allCountries.sort((a, b) => {
  //     if(a.name < b.name){
  //       return -1
  //     }
  //     if(a.name > b.name){
  //       return 1
  //     }
  //     return 0
  //   })
  // }

  changeCountry() {
    let newCountry = this.allCountries.find(country => country.id === this.selectedCountry);
    this.$emit('select', newCountry)
  }

  async loadInitialData() {
    await Countries.countriesGet().then((res) => {
      if (res.data.succeeded) {
        this.allCountries = res.data.resultData as Array<CountryViewModel>;

        // if (groups) {
        //   this.allCountries = [];
        //   groups.forEach((group: CountryGroupViewModel) => {
        //     group.countries.forEach((country: CountryViewModel) => {
        //       //if (group.name !== "Rest of the World") {
        //       this.allCountries = [...this.allCountries, country] as Array<CountryViewModel>;
        //       //}
        //     });
        //   });
        // }
      }
    });
  }
}
