
import { Options, Vue } from "vue-class-component";

@Options({
  props: {
    tileCategory: { type: String, default: "Product Category" },
    tileName: { type: String, default: "Product" },
    tileBgUrl: {
      type: String,
      default: "/img/clifton-product-images-1.1.jpg",
    },
    id: { type: String, default: "" },
  },
})
export default class ProductSelectorProductTile extends Vue {
  tileCategory = "Product Category";
  tileName = "Product";
  tileBgUrl = "/img/clifton-product-images-1.1.jpg";
}
