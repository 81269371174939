import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = ["onClick"]
const _hoisted_2 = { class: "element flex-centered flex-column col-12 p-1" }
const _hoisted_3 = { class: "top-section px-1 py-2 col-12" }
const _hoisted_4 = { class: "element-price flex-centered m-0 p-0 d-flex flex-column" }
const _hoisted_5 = {
  key: 0,
  class: "m-0 p-0 designer-section-title-sm text-center flex-centered px-1"
}
const _hoisted_6 = { style: {"font-weight":"600"} }
const _hoisted_7 = {
  key: 1,
  class: "m-0 p-0 designer-section-title-sm text-center flex-centered px-1"
}
const _hoisted_8 = { style: {"font-weight":"600"} }
const _hoisted_9 = { class: "element-text flex-centered m-0 p-0 d-flex flex-column" }
const _hoisted_10 = { class: "m-0 p-0 designer-section-title-sm text-center py-1 px-1" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.elements, (element, index) => {
    return (_openBlock(), _createElementBlock("div", {
      class: _normalizeClass(["element-tile d-flex col-4 col-sm-3 col-md-4 col-lg-6 col-xl-4 col-xxl-4 justify-content-center", { 'selected-element': _ctx.comparisonType === 'equals' ? _ctx.selected === element.id : _ctx.includesItem(element.id) }]),
      key: index,
      onClick: ($event: any) => (_ctx.handleClick(element))
    }, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", {
            class: "element-img flex-centered",
            style: _normalizeStyle(`background-image:url('${_ctx.uploadPath}/${element[_ctx.imgPath]}')`)
          }, null, 4)
        ]),
        _createElementVNode("div", _hoisted_4, [
          (element.prices.length && (_ctx.quantity > 0))
            ? (_openBlock(), _createElementBlock("p", _hoisted_5, [
                _createElementVNode("span", _hoisted_6, _toDisplayString(_ctx.correctSymbol(element.prices)) + _toDisplayString(_ctx.currency().symbol) + _toDisplayString(Math.abs(_ctx.calculatePrice(_ctx.getPriceByQuantity(element.prices), _ctx.currency().pricingMultiplier)).toFixed(_ctx.currency().decimalPlaces)), 1)
              ]))
            : (_openBlock(), _createElementBlock("p", _hoisted_7, [
                _createElementVNode("span", _hoisted_8, " +" + _toDisplayString(_ctx.currency().symbol) + "0.00 ", 1)
              ]))
        ]),
        _createElementVNode("div", _hoisted_9, [
          _createElementVNode("p", _hoisted_10, _toDisplayString(element.name), 1)
        ])
      ])
    ], 10, _hoisted_1))
  }), 128))
}