
import { mixins, Options } from "vue-class-component";
import { CalculatePrice, Currency, QuantityPerItem, ThousandSeparator, UploadPath } from "@/mixins/utilities";
import PreviewImage from "@/components/images/PreviewImage.vue";

@Options({
  components: { PreviewImage },
  props: {
    product: { default: {} },
  },
})
export default class CartSummaryProductTile extends mixins(CalculatePrice, Currency, UploadPath, QuantityPerItem, ThousandSeparator) {
}
