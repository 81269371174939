
import { mixins, Options, Vue } from "vue-class-component";
import type { PropType } from 'vue';

type StatementName = "designer" | "enquiries";
type StatementBody = {
  name: StatementName,
  text: string,
  linkTo: string,
  sendLeadIn: boolean,
  uppercase: boolean
};

@Options({
  props: {
    name: { type: String as PropType<StatementName>, default: "designer" },
  },
})
export default class StatementBanner extends Vue {
  name: StatementName = 'designer';
  statements: Array<StatementBody> = [
    {
      name: 'designer',
      text: `Design your customwear`,
      linkTo: "AllProductSelector",
      sendLeadIn: false,
      uppercase: true
    },
    {
      name: 'enquiries',
      text: `
      <div class='col-12 d-flex flex-column'>
        <div class='sm-text col-12'>Want some help with your design? <br>Our friendly expert team is on hand 24/7 to sweat the small details, so you don't have to.</div>
        <div class='col-12 lg-text text-uppercase mt-1'>Contact them here</div>
      </div>`,
      linkTo: "Enquiries",
      sendLeadIn: true,
      uppercase: false
    },
  ]

  handleToEquire() {
    if(this.statement) {
      let leadIn = `${window.location.pathname}${window.location.search || ""}`;
      this.$router.push({ name: this.statement.linkTo, query: {from: this.statement.sendLeadIn ? leadIn : undefined} })
    }
  }

  get statement() {
    return this.statements.find((item) => item.name === this.name);
  }
}
