import { openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  class: "py-3 py-sm-5 col-11 col-lg-9",
  name: "termly-embed",
  "data-id": "00708678-8a82-4aab-bd2b-e1c0df26e13f"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1))
}