
import { PropType, ref } from "vue";
import { Options, mixins } from "vue-class-component";
import CustomizationPicker from "@/features/product-builder/components/editor/CustomizationPicker.vue";
import { UploadPath, IsIOS } from "@/mixins/utilities";
import {ProductBuilderCustomizationViewModel} from "@/api-client";
import { CommonAction, SelectedFeatureItem } from "@/types";

@Options({
  components: {
    CustomizationPicker,
  },
  props: {
    fonts: {
      type: Array,
      default: [],
    },
    customizations: {
      type: Array,
      default: [],
    },
    selectedItem: {
      type: Object as PropType<SelectedFeatureItem>,
    },
    quantity: {
      type: Number,
      default: 1,
    },
    designExtras: {
      type: Array,
      default: [],
    },
    designItems: {
      type: Array,
      default: [],
    },
  },
  emits: ["selectCustomization", "handleFeature", "clear", "layer"],
})
export default class FeaturesTextEditor extends mixins(UploadPath, IsIOS) {
  fonts: Array<any> = [];
  customizations: Array<any> = [];
  selectedItem: SelectedFeatureItem | null = null;
  quantity = 0;
  designExtras: Array<any> = [];
  designItems: Array<any> = [];
  individualCustomizationActions: Array<CommonAction> = [
    {
      name: "Layer Up",
      imageSrc: "/img/designer/layer-up.png",
      emit: "layer",
      layer: 1,
      disabledOnConfigureExtra: false
    },
    {
      name: "Layer Down",
      imageSrc: "/img/designer/layer-down.png",
      emit: "layer",
      layer: -1,
      disabledOnConfigureExtra: false
    },
  ]
  iosDropdownDisplay = false

  get isIndividualCustomization() {
    return this.selectedItem && Object.prototype.hasOwnProperty.call(this.selectedItem, "isIndividualCustomization") ? this.selectedItem.isIndividualCustomization : false;
  }

  get individualCustomizationItem() {
    return this.designItems.find((x:any) => x.extraId === this.selectedItem!.id)
  }

  get selectedItemToDisplay() {
    return this.selectedItem && this.isIndividualCustomization ? this.individualCustomizationItem : this.selectedItem
  }

  get isExisting() {
    return this.designExtras.filter((i: any) => i.id == this.selectedItem!.id).length;
  }

  created() {
    if(this.selectedItem) {
      if (!this.selectedItem.font?.id) {
        this.selectedItem.font = this.fonts[0];
      }

      if (!this.selectedItem.customization?.id) {
        this.selectedItem.customization = this.customizations[0];
      }

      if (!this.selectedItem.colour) {
        this.selectedItem.colour = "#ffffff";
      }
    }

    this.$watch("individualCustomizationItem", () => {
      const extra = this.designExtras.find((i: any) => i.id == this.individualCustomizationItem.extraId)
      if(extra && this.selectedItem) {
        extra.colour = this.selectedItem.colour = this.individualCustomizationItem.colour;
        extra.font = this.selectedItem.font = this.individualCustomizationItem.font;
        extra.border = this.selectedItem.border = this.individualCustomizationItem.border;
      }
    }, { deep: true })
  }

  selectCustomization(customization: ProductBuilderCustomizationViewModel) {
    if(this.isIndividualCustomization) {
      this.individualCustomizationItem.customization = {
        code: customization.code,
        customizationTypeId: customization.customizationTypeId,
        id: customization.id,
        name: customization.name
      }
    }
    this.$emit('selectCustomization', customization, this.selectedItem);
  }

  handleAddUpdate(isExtraDismissed = true) {
    this.$emit("handleFeature", this.selectedItem, this.isExisting, isExtraDismissed);
  }

  handleReturnRemove() {
    this.$emit(this.isExisting ? "handleFeature" : "clear", this.selectedItem);
  }

  outsideClickListener(event: any) {
    if (this.iosDropdownDisplay) {
      if (event.target.closest(".ios-font-select") === null) {
        this.fontDropdownToggle()
      }
    }
  }

  fontDropdownToggle() {
    if(this.isIOS) {
      this.iosDropdownDisplay = !this.iosDropdownDisplay
      if(this.iosDropdownDisplay) {
        window.setTimeout(() => {
          document.querySelector(".ios-font-dropdown ul .font-selected")?.scrollIntoView({block: "nearest", inline: "nearest"})
        }, 100)
        window.addEventListener("click", (e) => { this.outsideClickListener(e) })
      }
      else {
        window.removeEventListener("click", (e) => { this.outsideClickListener(e) })
      }
    }
  }
}
