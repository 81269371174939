
import { Options, mixins } from "vue-class-component";
import { UploadPath } from "@/mixins/utilities";
import { CommonAction } from "@/types/index";

@Options({
  props: {
    isConfigureExtra: {
      type: Boolean,
      default: false
    },
    adminExtraOverride: {
      type: Boolean,
      default: false
    },
  },
  emits: ["layer", "swapView", "duplicate", "remove"]
})
export default class ArtAssetPicker extends mixins(UploadPath) {
  isConfigureExtra = false;
  adminExtraOverride = false;
  actions: Array<CommonAction> = [
    {
      name: "Layer Up",
      imageSrc: "/img/designer/layer-up.png",
      emit: "layer",
      layer: 1,
      disabledOnConfigureExtra: true
    },
    {
      name: "Layer Down",
      imageSrc: "/img/designer/layer-down.png",
      emit: "layer",
      layer: -1,
      disabledOnConfigureExtra: true
    },
    {
      name: "Swap View",
      imageSrc: "/img/designer/swap-view.png",
      emit: "swapView",
      disabledOnConfigureExtra: false
    },
    {
      name: "Duplicate",
      imageSrc: "/img/designer/duplicate.png",
      emit: "duplicate",
      disabledOnConfigureExtra: true
    },
    {
      name: "Remove",
      imageSrc: "/img/designer/remove.png",
      emit: "remove",
      disabledOnConfigureExtra: false
    },
  ];

  handleDisable(action:CommonAction) {
    if(!this.adminExtraOverride && !this.isConfigureExtra) {
      return true;
    }

    const isDuplicateAction = action.name == "Duplicate";
    return (this.isConfigureExtra && !action.disabledOnConfigureExtra) || (this.adminExtraOverride && !isDuplicateAction);
  }
}
