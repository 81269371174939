
import { ProductTagViewModel } from "@/api-client";
import { Options, Vue } from "vue-class-component";

@Options({
  props: {
    filters: Array,
    search: String,
  },
  emits: ["resetFilter", "filterDataChanged"],
})
export default class FilterBar extends Vue {
  toggleFilter = false;
  filters: Array<ProductTagViewModel> = [];
  search = "";
  searchTerm: string = this.search;
  selectedFilters: Array<string> = [];

  created() {
    this.$watch("searchTerm", () => {
      this.$emit("filterDataChanged", this.selectedFilters, this.searchTerm);
    });
  }

  toggleFilters(filter: string): void {
    if (this.selectedFilters.includes(filter)) {
      this.selectedFilters = this.selectedFilters.filter((item) => {
        return item !== filter;
      });
    } else {
      this.selectedFilters.push(filter);
    }
    this.$emit("filterDataChanged", this.selectedFilters, this.searchTerm);
  }

  toggleFilterFunction() {
    this.toggleFilter = false;
  }

  resetFilter() {
    this.searchTerm = "";
    this.selectedFilters = [];
    this.$emit("resetFilter");
  }
}
