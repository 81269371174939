
import { Options, Vue } from "vue-class-component";

@Options({
  props: {
    width: {
      type: Number,
      required: true,
    },
    height: {
      type: Number,
      required: true,
    },
    scalable: {
      type: Boolean,
      required: true,
    },
    selectedItem: { type: Object, required: true },
    isAdminOrder: {type: Boolean, required: false, default: false},
  },
})
export default class ControlBox extends Vue {}
