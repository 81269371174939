
import { ref } from "vue";
import { Options, mixins } from "vue-class-component";
import { CalculatePrice, Currency, UploadPath } from "@/mixins/utilities";

@Options({
  props: {
    options: {
      type: Array,
      required: true,
    },
    selected: { default: null },
    quantity: { default: null }
  },
  emits: ['selected']
})
export default class CustomizationPicker extends mixins(UploadPath, Currency, CalculatePrice) {
  selectedItem: any = null;
  selected: any;
  options: any;
  quantity = null;

  created() {
    this.selectedItem = ref(this.selected);

    this.options.forEach((option: any) => {
      if (option.id == this.selectedItem.id) {
        this.selectedItem = option;
      }
    });

    this.$emit("selected", this.selectedItem);
  }

  correctSymbol(prices:any) {
    const price = this.calculatePrice(this.getPriceByQuantity(prices) as number, this.currency().pricingMultiplier);
    return +price < 0 ? '-' : '+';
  }

  getPriceByQuantity(prices:any) {   
    if(this.quantity && prices.length) {
      let price = 0;
      var range = prices.filter((x:any) => (x.minimumQuantity ?? Number.MIN_SAFE_INTEGER) <= this.quantity! && (x.maximumQuantity ?? Number.MAX_SAFE_INTEGER) >= this.quantity!)[0];
      if(range != null)
      {
        price += range.price;
      }
      return price;
    }
  }

  emitChange(e: any) {
    this.$emit("selected", this.selectedItem);
  }
}
