
import { Options, Vue } from "vue-class-component";

@Options({
  props: {
    heading: { default: ''},
    isH1: { default: true},
    text: { default: '' },
    buttonText: { default: '' },
    buttonLink: { default: 'AboutUs' },
    floatingImg: { default: '' },
    imageSrc: { default: '' },
    uniqueHeight: { default: false },
    keepHeight: { default: false }
  }
})
export default class GenericBanner extends Vue {
}
