import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.statement)
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.handleToEquire && _ctx.handleToEquire(...args))),
        class: "statement-banner-container"
      }, [
        _createElementVNode("div", {
          class: _normalizeClass(["statement-banner flex-centered text-center px-2 px-lg-3 py-3", {'text-uppercase': _ctx.statement.uppercase}]),
          innerHTML: _ctx.statement.text
        }, null, 10, _hoisted_1)
      ]))
    : _createCommentVNode("", true)
}