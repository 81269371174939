import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1cd1abb8"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "currency-group-container d-flex justify-content-center"
}
const _hoisted_2 = { class: "col-12 text-md-center h-xs-secondary text-uppercase pb-1 pb-sm-0" }
const _hoisted_3 = ["onClick"]
const _hoisted_4 = { class: "d-flex currency-details" }
const _hoisted_5 = { class: "ps-2 d-flex flex-column justify-content-center currency-name p-xs-primary" }
const _hoisted_6 = { class: "m-0 p-0 d-none d-sm-inline" }
const _hoisted_7 = { class: "m-0 p-0" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.countryGroup.countries)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", null, [
          _createElementVNode("h4", _hoisted_2, _toDisplayString(_ctx.countryGroup.name === "Rest of the World" ? "Rest of World" : _ctx.countryGroup.name), 1),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.countryGroup.countries, (country) => {
            return (_openBlock(), _createElementBlock("div", {
              class: _normalizeClass(["col-12 d-flex align-items-center pb-2 pb-sm-3", { 'selected': _ctx.selected === country.id }]),
              key: country.id,
              onClick: ($event: any) => (this.$emit('select', country))
            }, [
              _createElementVNode("div", _hoisted_4, [
                (country.code !== 'XX')
                  ? (_openBlock(), _createElementBlock("i", {
                      key: 0,
                      class: _normalizeClass(["round-flag-icon currency-flag", country.code === 'EU' ? `round-flag-european_union` : `round-flag-${country.code.toLowerCase()}`])
                    }, null, 2))
                  : (_openBlock(), _createElementBlock("div", {
                      key: 1,
                      class: "currency-bg-flag currency-flag text-center",
                      style: _normalizeStyle(`background-image:url('/img/flags/rest-of-world-flag-dark.png')`)
                    }, null, 4)),
                _createElementVNode("div", _hoisted_5, [
                  _createElementVNode("p", _hoisted_6, _toDisplayString(country.name), 1),
                  _createElementVNode("p", _hoisted_7, _toDisplayString(country.currency.symbol) + " " + _toDisplayString(country.currency.code), 1)
                ])
              ])
            ], 10, _hoisted_3))
          }), 128))
        ])
      ]))
    : _createCommentVNode("", true)
}