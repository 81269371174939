
import { Options, mixins } from "vue-class-component";
import { UploadPath } from "@/mixins/utilities";
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from "axios";
import BackgroundReplaceImageUploader from "@/features/product-builder/components/editor/BackgroundReplaceImageUploader.vue";

const STATUS_INITIAL = 0,
  STATUS_SAVING = 1,
  STATUS_SUCCESS = 2,
  STATUS_FAILED = 3;

@Options({
  components: {
    BackgroundReplaceImageUploader
  },
  props: {
    // modelValue: {
    //   type: Object,
    //   required: true,
    // },
    customization: {
      type: Object,
      required: false,
    },
    sectionHeight: {default: 0}
  },
  emits: ['addArtAsset', 'uploading']
})
export default class ArtAssetUpload extends mixins(UploadPath) {
  currentStatus: number = STATUS_INITIAL;
  userImages: any = [];
  uploadedFiles: any;
  uploadError: any;
  customization: any;
  sectionHeight = 500;
  showUploader = true

  created() {
    this.showUploader = true
    this.userImages = [...this.localStoreImages];
  }

  emitLoading(value:boolean) {
    this.$emit('uploading', value)
  }

  setImageFile(event: any) {
    (this.$refs as any ).bgrep.drawImage(event.target.files[0]);
    this.showUploader = false;
  }

  imageObject(artAsset: any) {
    return {
      type: "image",
      x: 340,
      y: 400,
      rotate: 0,
      order: 2,
      height: 100,
      width: 100,
      src: `${artAsset}`,
      customization: this.customization,
      isFromLibrary: false,
      border: {
        width: 0,
        style: 0,
        colour: "",
      }
    };
  }

  get isInitial() {
    return this.currentStatus === STATUS_INITIAL;
  }
  get isSaving() {
    return this.currentStatus === STATUS_SAVING;
  }
  get isSuccess() {
    return this.currentStatus === STATUS_SUCCESS;
  }
  get isFailed() {
    return this.currentStatus === STATUS_FAILED;
  }

  get localStoreImages() {
    var images = [];
    let imageJSON = localStorage.getItem("userImages");
    if (imageJSON) {
      images = JSON.parse(imageJSON);
    }
    return images;
  }

  removeFromBackgroundReplace() {
    let refs = this.$refs as any
    if(refs.file && refs.bgrep) {
      refs.bgrep.imgs = '';
      refs.bgrep.recoverImgUrl = '';
      refs.file.value = ""
      this.showUploader = true;
    }
  }

  addToLocalStorage(image: any) {
    this.localStoreImages.push(image);
    localStorage.setItem("userImages", JSON.stringify(this.localStoreImages));
  }

  deleteItem(image: any) {
    this.localStoreImages.splice(this.localStoreImages.indexOf(image), 1);
    localStorage.setItem("userImages", JSON.stringify(this.localStoreImages));
    this.userImages = [...this.localStoreImages];
  }

  filesChange(fileList: any) {
    let files = [...fileList]
    if (!files.length) return;

    files.forEach((file: any) => {
      const formData = new FormData();
      formData.append("file", file);
      this.save(formData);
    });
  }

  b64toBlob(b64Data: any, contentType='', sliceSize=512){
    const byteCharacters = atob(b64Data);
    const byteArrays = [];
    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);
      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }
    const blob = new Blob(byteArrays, {type: contentType});
    return blob;
  }

  backgroundRemoved(imgData: any){
    this.emitLoading(true)
    let contentType = 'image/png';
    imgData = imgData.replace('data:image/png;base64,', '');
    const blob = this.b64toBlob(imgData, contentType);
    let data = new FormData()
    data.append('file', blob, "uploadedAsset.png")
    this.save(data)
    this.emitLoading(false)
  }

  async save(formData: any) {
    // upload data to the server
    this.emitLoading(true)
    this.currentStatus = STATUS_SAVING;

    await this.upload(formData)
      .then((x: any) => {
        this.addToLocalStorage(x);
        this.userImages.push(x);
        this.currentStatus = STATUS_INITIAL;
        this.removeFromBackgroundReplace()
        this.$notify({ type: "success", text: "Image uploaded! Select your image to add it to your design." });
        this.emitLoading(false)
      })
      .catch((err: any) => {
        this.emitLoading(false)
        this.uploadError = err.response;
        this.currentStatus = STATUS_FAILED;
      });
  }

  async upload(formData: any) {
    const url = `${this.uploadPath}`;
    return await globalAxios
      .post(url, formData)
      .then((x: any) => x.data)
      .then((img: any) => img.relativePath);
  }
}
