
import { ProductCategoryMenuGroupViewModel } from "@/api-client";
import { Options, Vue } from "vue-class-component";

@Options({
  props: {
    menuItems: { default: null }
  },
})
export default class ProductMenu extends Vue {
  menuItems: Array<ProductCategoryMenuGroupViewModel> = []

  get navColumns() {
    let count = 0;
    this.menuItems.forEach(item => {
      if(item.categories.length) {
        count += 1;
      }
    })

    if(count <= 4) {
      return {count: count, col: 12 / count};
    }

    return {count: count, col: 3};
  }

  get navMax() {
    let count = 0;
    this.menuItems.forEach(item => {
      if(item.categories.length) {
        count += 1;
      }
    })

    if(count <= 4) {
      return 100 / count;
    }

    return 25;
  }
}
