
import { Options, Vue } from "vue-class-component";

@Options({
  props: {
    category: { type: String, default: "SweatShirts & Q-Zips" },
    productInfo: {
      type: String,
      default: "Basic product information detailed here.",
    },
    categoryBgUrl: {
      type: String,
      default: "/img/clifton-category-image-blazers.jpg",
    },
  },
})
export default class ProductSelectorCategoryTile extends Vue {
  category = "";
  productInfo = "";
  categoryBgUrl = "";
}
