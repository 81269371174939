import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveDynamicComponent as _resolveDynamicComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment } from "vue"

const _hoisted_1 = ["font-size", "transform", "transform-origin", "xlink:href", "fill", "font-family", "font-weight", "font-style", "stroke", "stroke-width", "stroke-dasharray"]
const _hoisted_2 = { key: 0 }
const _hoisted_3 = ["text-anchor", "x"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.modelValue)
    ? (_openBlock(), _createElementBlock("text", {
        key: 0,
        ref: "text",
        "font-size": _ctx.modelValue.fontSize ?? 40,
        transform: _ctx.textTransform(),
        "transform-origin": _ctx.textTransformOrigin(),
        "xlink:href": _ctx.modelValue.src,
        fill: _ctx.modelValue.colour ?? '#000000',
        "font-family": _ctx.modelValue.font.name ?? 'Arial',
        "font-weight": _ctx.modelValue.fontWeight ?? 'normal',
        "font-style": _ctx.modelValue.fontStyle ?? 'normal',
        stroke: _ctx.modelValue.border?.colour ?? 'none',
        "stroke-width": _ctx.modelValue.border?.width ?? 2,
        "stroke-dasharray": _ctx.modelValue.border?.style ?? 0
      }, [
        (_ctx.modelValue.font.fontFileUrl)
          ? (_openBlock(), _createElementBlock("defs", _hoisted_2, [
              (_openBlock(), _createBlock(_resolveDynamicComponent('style'), null, {
                default: _withCtx(() => [
                  _createTextVNode(" @font-face{font-family:'" + _toDisplayString(_ctx.modelValue.font.name) + "';font-style:" + _toDisplayString(_ctx.modelValue.font.fontStyle) + ";font-weight:" + _toDisplayString(_ctx.modelValue.font.fontWeight) + ";font-display:block;src:url(" + _toDisplayString(`${this.uploadPath}/${_ctx.modelValue.font.fontFileUrl}`) + ")} ", 1)
                ]),
                _: 1
              }))
            ]))
          : _createCommentVNode("", true),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.splitText, (line, index) => {
          return (_openBlock(), _createElementBlock("tspan", {
            "text-anchor": _ctx.modelValue.alignment ?? 'middle',
            dy: "1.2em",
            x: _ctx.spanX,
            key: index
          }, _toDisplayString(line), 9, _hoisted_3))
        }), 128))
      ], 8, _hoisted_1))
    : _createCommentVNode("", true)
}