
import { Options, mixins } from "vue-class-component";

import { UploadPath } from "@/mixins/utilities";
import ElementTile from "./ElementTile.vue";

@Options({
  components: { ElementTile },
  props: {
    styles: {
      type: Object,
      required: true,
    },
    selectedStyle: {
      type: Object,
      required: true,
    },
    selectedDesign: {
      type: Object,
      required: true,
    },
    selectedTab: {
      type: String,
    },
    designing: {
      default: false
    },
    savePatternMsg: {
      type: String,
      default: ''
    },
    quantity: { default: null },
    sectionHeight: { default: 0 },
    isConfigureExtra: {
      default: false
    }
  },
})
export default class StyleDesignPicker extends mixins(UploadPath) {
  selectedStyle: any = null;
  selectedDesign: any = null;
  sectionHeight = 630
  savePatternMsg = ''
  designing = false
  
  created() {
    this.selectTab('styles')
  }

  mounted() {
    // This is to fix the issue of the wrong tab being selected on product load (bug only occurs on staging).
    let stylesTab = document.getElementById("styles-tab")
    let coloursTab = document.getElementById("colours-tab")
    let designsTab = document.getElementById("designs-tab")

    if(coloursTab?.classList.contains('active')) {
      coloursTab.classList.remove('active')
      stylesTab?.classList.add('active')
    }

    if(designsTab?.classList.contains('active')) {
      designsTab.classList.remove('active')
      stylesTab?.classList.add('active')
    }
  }

  selectTab(tab:string) {
    if((this.designing == true) && (tab !== 'colours')) {
      this.$notify({ type: "error", text: this.savePatternMsg, ignoreDuplicates: true, duration: -1 });
    } else {
      this.$emit('tab', tab)
    }
  }

  selectStyle(style: any) {
    this.$emit("selectStyle", style);
    this.$emit("selectDesign", style.designs[0]);
  }

  selectDesign(design: any) {
    this.$emit("selectDesign", design);
  }
}
