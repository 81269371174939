
import { Options, Vue } from "vue-class-component";
import "round-flag-icons/css/round-flag-icons.css";

@Options({
  components: { },
  props: {
    group: { default: "" },
    countryGroup: { default: [] },
    selected: { default: "" },
  },
  emits: ["select"],
})
export default class CurrencyGroup extends Vue {
}
