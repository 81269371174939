
import { Options, Vue } from "vue-class-component";

@Options({
  props: {
    sectionHeight: {default: 0}
  },
  emits: ["reset"]
})
export default class ResetTemplate extends Vue {
  sectionHeight = 500;
}
